import React, { useEffect, useRef, useState } from 'react'
import { Flex } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  DEFAULT,
  DROPDOWN_BUTTON,
  NIGHT,
  TABLE_DEFAULT_ROW_HEIGHT,
} from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultTagBlock from '../../../../components/MyDefaultTagBlock/MyDefaultTagBlock'
import MyDefaultTableList from '../../../../components/MyDefaultTable/MyDefaultTableList/MyDefaultTableList'
import MyDefaultTableTitle from '../../../../components/MyDefaultTable/MyDefaultTableTitle/MyDefaultTableTitle'
import MyDefaultTableTags from '../../../../components/MyDefaultTable/MyDefaultTableTags/MyDefaultTableTags'
import SVGMyDefaultEditIcon from '../../../../icons/SVG/SVGMyDefaultEditIcon'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import SVGMyDefaultDeleteIcon from '../../../../icons/SVG/SVGMyDefaultDeleteIcon'
import './DripSequenceTemplate.scss'

const DripSequenceTemplate = ({ organization, screenSize, setPageTitle }) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [sequencesTemplateData, setSequencesTemplateData] = useState({
    list: [],
    total: 0,
  })

  const layoutRef = useRef(null)

  const isActivitySequencePresent =
    sequencesTemplateData &&
    sequencesTemplateData.list &&
    sequencesTemplateData.list.some((seq) => seq.triggeredOn === 'activity')

  const getSequencesData = async () => {
    setLoading(true)
    const result = await organizationActions.getPageListTemplateSequence(
      page,
      TABLE_DEFAULT_ROW_HEIGHT
    )
    if (result.success) {
      let data = []
      if (page === 0) {
        data = result.data
      } else {
        data = [...sequencesTemplateData.list, ...result.data]
      }
      setSequencesTemplateData({
        list: data,
        total: result.max,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    setPageTitle(getText('WORD_DRIP_SEQUENCE_TEMPLATES'))
  }, [])

  useEffect(() => {
    if (organization && organization._id) {
      getSequencesData()
    }
  }, [organization, page])

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      disableHeader={!sequencesTemplateData?.list?.length && !loading}
      isAdvanced
      headerProps={{
        right: [
          {
            type: DROPDOWN_BUTTON,
            title: getText('WORD_CREATE_WORKING_HOURS_SEQUENCE'),
            onClick: () => {
              navigate(
                `/organization/settings/${organization._id}/dripsequence/template/create`,
                { state: { isActivitySequencePresent, type: DEFAULT } }
              )
            },
            onItemClick: (item) => {
              navigate(
                `/organization/settings/${organization._id}/dripsequence/template/create`,
                { state: { isActivitySequencePresent, type: item.key } }
              )
            },
            items: [
              {
                key: DEFAULT,
                label: (
                  <Flex align='center' gap={20} style={{ lineHeight: 'normal' }}>
                    {getText('WORD_CREATE_WORKING_HOURS_SEQUENCE')}
                    <MyDefaultTagBlock
                      title={getText('WORD_DEFAULT')}
                      backgroundColor={'var(--greenLight)'}
                      titleColor={'var(--green)'}
                    />
                  </Flex>
                ),
              },
              {
                key: NIGHT,
                label: getText('WORD_CREATE_AFTER_HOURS_SEQUENCE'),
              },
            ],
          },
        ],
        screenSize: screenSize,
      }}
    >
      <MyDefaultTableList
        pageStart={page}
        loading={loading}
        data={sequencesTemplateData.list || []}
        getScrollParent={() => layoutRef?.current}
        onLoadMore={() => {
          if (
            !loading &&
            sequencesTemplateData?.list?.length < sequencesTemplateData?.total
          ) {
            setLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={sequencesTemplateData?.total || 0}
        columns={[
          {
            title: getText('TEXT_SEQUENCE_NAME'),
            dataIndex: 'name',
            render: (_, { name }) => {
              return <MyDefaultTableTitle title={name || ''} />
            },
          },

          {
            title: getText('WORD_TOUCHPOINTS'),
            dataIndex: 'sources_names',
            width: 420,
            render: (_, { sources_names }) => {
              return <MyDefaultTableTags list={sources_names} />
            },
          },
          {
            title: '',
            dataIndex: 'actions',
            width: 60,
            render: (_, item) => {
              return (
                <Flex align='center' gap={20}>
                  <SVGMyDefaultEditIcon
                    onClick={() => {
                      navigate(
                        `/organization/settings/${organization._id}/dripsequence/template/edit`,
                        {
                          state: {
                            sequenceData: item,
                            isActivitySequencePresent,
                          },
                        }
                      )
                    }}
                  />
                  <ConfirmPopup
                    title={getText(
                      'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SEQUENCE'
                    )}
                    placement={'topRight'}
                    onConfirm={async () => {
                      const result =
                        await organizationActions.deleteSequenceTemplate(item._id)
                      if (result.success) {
                        setSequencesTemplateData((ov) => {
                          const newList = ov.list.filter(
                            (seq) => seq._id !== item._id
                          )
                          return { list: [...newList], total: ov.total }
                        })
                        notifySuccess(
                          getText('TEXT_SEQUENCE_WAS_DELETED_SUCCESSFULLY')
                        )
                      } else {
                        notifyError(getTextServerError(result.errMsg))
                      }
                    }}
                    okText={getText('WORD_YES')}
                    cancelText={getText('WORD_NO')}
                    trigger={<SVGMyDefaultDeleteIcon />}
                  />
                </Flex>
              )
            },
          },
        ]}
      />
    </MyDefaultPageLayout>
  )
}

export default DripSequenceTemplate
