import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText, getTextServerError } from '../../../../lang'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import organizationActions from '../../../../store/modules/organizationActions'
import MatForm from '../../../../components/Form/MatForm'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultUploadWrapperBig from '../../../../components/MyDefaultUpload/MyDefaultUploadWrapperBig/MyDefaultUploadWrapperBig'
import { ACCEPT_IMAGE_PDF, MEDIATYPES_IMAGE_PDF } from '../../../../utils'
import SendMessageTab from './SendMessageTab'
import { TEMPLATE_TYPES } from '.'
import './TemplateEdit.scss'

const TemplateEdit = ({ organization, templateType, setFooterButtons }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [currentCursorPositionSubject, setCurrentCursorPositionSubject] = useState(0)
  const [selectedTab, setSelectedTab] = useState('')
  const [uploadFiles, setUploadFiles] = useState([])

  const textAreaRef = useRef()
  const textAreaSubjectRef = useRef()
  const [form] = Form.useForm()
  let templateData = location.state && location.state

  useEffect(() => {
    setFooterButtons && setFooterButtons(['save', 'cancel'])
    setSelectedTab(organization.languages[0])
    const replacedMediaFilds = mediaFieldsReplacer(
      templateData && templateData.media
    )
    setUploadFiles(replacedMediaFilds)
  }, [])

  const addNameInMessage = (word) => {
    let newString = form.getFieldValue('text')[selectedTab]
    let newText = [
      newString && newString.slice(0, currentCursorPosition),
      word,
      newString && newString.slice(currentCursorPosition),
    ].join('')
    form.getFieldValue('text')[selectedTab] = newText
    setCurrentCursorPosition(currentCursorPosition + word.length)
    textAreaRef.current &&
      textAreaRef.current.autoFocus(currentCursorPosition + word.length)
  }

  const addNameInSubject = (word) => {
    console.log('word.length :>> ', word.length, '{{customer_first_name}}'.length)
    let newString = form.getFieldValue('subject')[selectedTab]
    let newText = [
      newString && newString.slice(0, currentCursorPositionSubject),
      word,
      newString && newString.slice(currentCursorPositionSubject),
    ].join('')
    form.getFieldValue('subject')[selectedTab] = newText
    setCurrentCursorPositionSubject(currentCursorPositionSubject + word.length)
    textAreaSubjectRef.current &&
      textAreaSubjectRef.current.autoFocus(
        currentCursorPositionSubject + word.length
      )
  }

  const mediaFieldsReplacer = (data = []) => {
    if (!data || !data.length) {
      return []
    }
    data = data.map((item) => {
      item.url = item.mediaUrl
      item.key = item._id
      item.uid = item._id

      return item
    })
    return data
  }

  const correctRequestMediaDataFields = (data) => {
    return data.map((item) => {
      return {
        mediaUrl: item.url,
        mediaContentType: item.mediaContentType,
      }
    })
  }

  const onUploadMedia = (media) => {
    let currentMedia = uploadFiles.map((item) => {
      if (item.id !== media.id) {
        return item
      }
      return media
    })
    setUploadFiles(currentMedia)
  }

  const onFinish = async (values) => {
    let obj = {}

    let objSend = {
      name: values.name,
      text: values.text,
      type: templateType,
      subject: values.subject,
    }
    objSend.media = correctRequestMediaDataFields(uploadFiles)
    obj._id = templateData && templateData._id

    let result
    if (obj._id) {
      result = await organizationActions.saveUpdateTemplet(
        objSend,
        obj._id,
        organization._id
      )
    } else {
      result = await organizationActions.saveNewTemplet(objSend, organization._id)
    }
    if (result && result.success) {
      notifySuccess(getText('TEXT_TEMPLATE_WAS_SAVED_SUCCESSFULLY'))
      navigate(-1)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }

  const listVariables = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'user_first_name',
      value: '{{user_first_name}}',
    },
    {
      label: 'user_last_name',
      value: '{{user_last_name}}',
    },
    {
      label: 'organization_name',
      value: '{{organization_name}}',
    },
    {
      label: 'user_phone',
      value: '{{user_phone}}',
    },
    {
      label: 'user_email',
      value: '{{user_email}}',
    },
    ...(templateType === TEMPLATE_TYPES.SMS
      ? [
          {
            label: 'review_invite_url',
            value: '{{review_invite_url}}',
          },
        ]
      : []),
    {
      label: 'year',
      value: '{{year}}',
    },
    {
      label: 'make',
      value: '{{make}}',
    },
    {
      label: 'model',
      value: '{{model}}',
    },
    {
      label: 'VIN',
      value: '{{VIN}}',
    },
    {
      label: 'trade_year',
      value: '{{trade_year}}',
    },
    {
      label: 'trade_make',
      value: '{{trade_make}}',
    },
    {
      label: 'trade_model',
      value: '{{trade_model}}',
    },
    {
      label: 'trade_VIN',
      value: '{{trade_VIN}}',
    },
    {
      label: 'offer_amount',
      value: '{{offer_amount}}',
    },
    {
      label: 'price_estimate',
      value: '{{price_estimate}}',
    },
  ]

  return (
    <MyDefaultPageLayout disableHeader>
      <MyDefaultBlock>
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            name: templateData ? templateData.name : '',
            text: templateData ? templateData.text : {},
            subject: templateData ? templateData.subject : {},
          }}
        >
          <MyDefaultInputText
            isForm
            name='name'
            label={getText('LABEL_TEMPLATE_NAME')}
            required
            maxLength={100}
          />
          <SendMessageTab
            form={form}
            textAreaRef={textAreaRef}
            textAreaSubjectRef={textAreaSubjectRef}
            templateData={templateData}
            templateType={templateType}
            variablesData={listVariables}
            onAddVariableSubjectClick={addNameInSubject}
            languages={organization.languages}
            onChangeTextarea={(e) => {
              setCurrentCursorPosition(e)
            }}
            onClickTextarea={(e) => {
              setCurrentCursorPosition(e)
            }}
            onKeyUpTextarea={(e) => {
              setCurrentCursorPosition(e)
            }}
            onChangeTabs={(lng) => {
              setSelectedTab(organization?.languages[lng])
            }}
            onChangeSubject={(e) => {
              setCurrentCursorPositionSubject(e.target.selectionStart)
            }}
            onKeyUpSubject={(e) => {
              setCurrentCursorPositionSubject(e.target.selectionStart)
            }}
            onClickSubject={(e) => {
              setCurrentCursorPositionSubject(e.target.selectionStart)
            }}
            onAddTemplateText={addNameInMessage}
          />
          {Boolean(templateType === TEMPLATE_TYPES.SMS) && (
            <MyDefaultUploadWrapperBig
              disableMargins
              fileList={uploadFiles}
              onUpload={(media) => onUploadMedia(media)}
              onStartUpload={(newFile) => {
                setUploadFiles((oldUploadFile) => {
                  oldUploadFile.push(newFile)
                  return oldUploadFile
                })
              }}
              mediaTypes={MEDIATYPES_IMAGE_PDF}
              accept={ACCEPT_IMAGE_PDF}
              beforeUploadProps
              errMsg={getText('MSG_ONLY_IMAGE_OR_PDF_FORMATS_CAN_BE_ADDED')}
              customType={[{ size: 1, type: 'application/pdf' }]}
              title={getText('WORD_PLUS_ADD_IMAGE_OR_PDF')}
              onDelete={(uploadFiles) => {
                setUploadFiles(uploadFiles)
              }}
            />
          )}
        </MatForm>
      </MyDefaultBlock>
    </MyDefaultPageLayout>
  )
}

export default TemplateEdit
