import React, { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getText } from '../../../lang'
import MatSelect from '../../../components/MatSelect'
import { DashboardContext } from '../Dashboard'

const DashboardSelectOrgLoc = () => {
  const { selectedDataType, setSelectedDataType } = useContext(DashboardContext)
  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const list_org_loc = [
    {
      label: getText('WORD_ORGANIZATION'),
      value: 'org',
    },
    {
      label: getText('WORD_LOCATION'),
      value: 'loc',
    },
  ]
  let sel_org_loc = list_org_loc.find((obj) => obj.value === selectedDataType)

  return (
    (user.isSuperAdmin || user.isAdmin) && (
      <div className='dashboard-filter-select'>
        <MatSelect
          secondary
          value={sel_org_loc.label}
          options={list_org_loc}
          onSelect={(value) => {
            setSelectedDataType(value)
          }}
        />
      </div>
    )
  )
}

export default memo(DashboardSelectOrgLoc)
