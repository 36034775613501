import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import statActions from '../../../store/modules/statActions'
import { getText } from '../../../lang'
import { convertDashboardStatisticDataMonth } from '../../../utils'
import SVGDashVideoConsuptionIcon from '../../../icons/SVG/SVGDashVideoConsuptionIcon'
import SVGDashVideoTotalSentIcon from '../../../icons/SVG/SVGDashVideoTotalSentIcon'
import SVGDashVideoWatchRateIcon from '../../../icons/SVG/SVGDashVideoWatchRateIcon'
import SVGDashVideoVideoLeadsIcon from '../../../icons/SVG/SVGDashVideoVideoLeadsIcon'
import DashboardProgressBlock from '../DashboardComponents/DashboardProgressBlock/DashboardProgressBlock'
import DashboardStatistics from '../DashboardComponents/DashboardStatistics/DashboardStatistics'
import Div from '../../../components/MatDiv/Div'
import utilNumber from '../../../utils/utilNumber'
import { notifyError } from '../../../utils/Notify'
import { CUSTOM, ORG } from '../../../devOptions'
import { DashboardContext } from '../Dashboard'
import LoadingSpinner from '../../../components/LoadingSpinner'
import useWindowSize from '../../../utils/useWindowSize'
import './Video.scss'

const Video = () => {
  const { selectedRange, selectedDataType, selectCustomRange } =
    useContext(DashboardContext)

  const { isMobile, isTabletPortrait } = useWindowSize()

  const [loading, setLoading] = useState(true)
  const [videoData, setVideoData] = useState({})

  const location = useSelector((state) => {
    return state.authReducer.user.location
  })

  const isResponsiveSize = isMobile || isTabletPortrait

  const fill = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }
    setLoading(true)
    let locationId = selectedDataType === ORG ? '' : location._id
    const result = await statActions.getVideoStatsDashboard(
      selectedRange,
      locationId,
      selectCustomRange.start_date,
      selectCustomRange.end_date
    )
    if (result.success) {
      setVideoData(result.data)
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
    setLoading(false)
  }, [location._id, selectedRange, selectedDataType, selectCustomRange])

  useEffect(() => {
    fill()
  }, [fill])

  const videoChartData = convertDashboardStatisticDataMonth(
    videoData?.video_chart,
    isResponsiveSize
  )

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Div className='video_card_wrapper'>
      <Div className='video_card_wrapper--items'>
        <DashboardProgressBlock
          align={'center'}
          color={'green'}
          icon={<SVGDashVideoConsuptionIcon />}
          title={getText('WORD_CONTENT_CONSUMPTION')}
          content={utilNumber.formatSecondsToHms(
            videoData?.video_stats?.count_seconds_watched || 0
          )}
          tooltipTitle={getText('TOOLTIP_VIDEO_CONTENT_CONSUPTION')}
        />
        <DashboardProgressBlock
          color={'purple'}
          icon={<SVGDashVideoWatchRateIcon />}
          description={getText(
            'WORD_PERCENTAGE_OF_PEOPLE_THAT_HAVE_VIEWED_YOUR_VIDEO'
          )}
          title={getText('WORD_WATCH_RATE')}
          content={`${videoData?.video_stats?.video_watched_percentage || 0}%`}
          percent={videoData?.video_stats?.video_watched_percentage || 0}
          strokeColor={{
            '0%': '#40D7E2',
            '100%': '#7D71FF',
          }}
          tooltipTitle={getText('TOOLTIP_VIDEO_WATCH_RATE')}
        />
        <DashboardProgressBlock
          color={'pink'}
          icon={<SVGDashVideoVideoLeadsIcon />}
          title={getText('WORD_LEADS_WHERE_VIDEO_SENT')}
          content={`${videoData?.video_stats?.leads_with_videos_percentage || 0}%`}
          percent={videoData?.video_stats?.leads_with_videos_percentage || 0}
          strokeColor={{
            '0%': '#FE75C6',
            '100%': '#F97E7B',
          }}
          tooltipTitle={getText('TOOLTIP_VIDEO_OF_LEADS_WHERE_VIDEO_SENT')}
          align={'center'}
        />
      </Div>
      <DashboardStatistics
        number={videoData?.video_stats?.videos_count || 0}
        data={videoChartData}
        icon={<SVGDashVideoTotalSentIcon />}
        title={getText('WORD_TOTAL_VIDEOS_SENT')}
        tooltipTitle={getText('TOOLTIP_VIDEO_TOTAL_VIDEOS_SENT')}
        responsiveChartHeight={450}
      />
    </Div>
  )
}

export default memo(Video)
