import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import SVGFollowedLinkIcon from '../../../../../icons/SVG/SVGFollowedLinkIcon'
import SVGCheckedIcon from '../../../../../icons/SVG/SVGCheckedIcon'
import SVGShowVideoIcon from '../../../../../icons/SVG/SVGShowVideoIcon'
import SVGWatchedTimesIcon from '../../../../../icons/SVG/SVGWatchedTimesIcon'
import SVGPlayIcon from '../../../../../icons/SVG/SVGPlayIcon'
import { myWebViewPost } from '../../../../../utils'
import { getText } from '../../../../../lang'
import CenterMessageReview from '../CenterMessageReview'

const CenterVideo = (props) => {
  const { message, index, conversation, screenSize } = props

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  return (
    <CenterMessageReview
      screenSize={screenSize}
      conversation={conversation}
      message={message}
      index={index}
      title={getText('WORD_SENT_CLIENT_VIDEO')}
      infoValueList={[
        {
          infoViewTitle: getText('WORD_SHOW_VIDEO'),
          icon: <SVGShowVideoIcon />,
          bodyIcon: <SVGPlayIcon />,
          noIcon: false,
          active: message.url,
          style: { cursor: 'pointer' },
          onClick: async () => {
            if (isMobileApp) {
              myWebViewPost(message.url)
            } else {
              window.open(message.url)
            }
          },
        },
        {
          infoViewTitle: getText('WORD_FOLLOWED_LINK'),
          icon: (
            <SVGFollowedLinkIcon color={'#48AF80'} backgroundcolor={'#EAF6F1'} />
          ),
          bodyIcon: <SVGCheckedIcon />,
          noIcon: false,
          active: message.metrics.link_followed_count,
        },
        {
          infoViewTitle: getText('WORD_WATCHED_TIMES'),
          icon: <SVGWatchedTimesIcon />,
          count: message.metrics.video_watched_count || 0,
          noIcon: true,
          active: message.metrics.video_watched_count || 0,
        },
      ]}
    />
  )
}

export default memo(CenterVideo)
