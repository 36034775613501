import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd/es'
import { getText, getTextServerError } from '../../../../lang'
import { getLongName, MEDIATYPES_IMG_VIDEO_PDF } from '../../../../utils'
import SVGOrgSMSIcon from '../../../../icons/SVG/SVGOrgSMSIcon'
import SVGOrgEmailIcon from '../../../../icons/SVG/SVGOrgEmailIcon'
import { useSocket } from '../../../../utils/socket'
import {
  APPLICATION_PDF,
  DAYS,
  EMAIL,
  HOURS,
  IMAGE,
  IMMEDIATELY,
  MINUTES,
  NIGHT,
  PDF,
  SMS,
  TOUCHPOINT,
  VIDEO,
} from '../../../../devOptions'
import MyDefaultBlockSubtitle from '../../../../components/MyDefaultBlock/MyDefaultBlockSubtitle'
import MyDefaultRadioButton from '../../../../components/MyDefaultRadioButton/MyDefaultRadioButton'
import MyDefaultSendFollowUp from '../../../../components/MyDefaultSendFollowUp/MyDefaultSendFollowUp'
import MyDefaultRadioTabs from '../../../../components/MyDefaultRadioButton/MyDefaultRadioTabs/MyDefaultRadioTabs'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultTextarea from '../../../../components/MyDefaultTextarea/MyDefaultTextarea'
import MyDefaultAddVariablesInMessage from '../../../../components/MyDefaultAddVariablesInMessage/MyDefaultAddVariablesInMessage'
import MyDefaultUploadWrapperBig from '../../../../components/MyDefaultUpload/MyDefaultUploadWrapperBig/MyDefaultUploadWrapperBig'
import { notifyError } from '../../../../utils/Notify'
import { uploadAndFetchPOStFile } from '../../../../utils/UrlHelper'
import useDynamicWatch from '../../../../utils//useDynamicWatch'

const AutoReplyPanel = (props) => {
  const {
    selectedAiBotMessage,
    setSelectedAiBotMessage,
    sequenceData,
    languages,
    triggeredOn,
    selectedIndex,
    form,
    field,
    type,
    screenSize,
  } = props

  const [selectedLangTab, setSelectedLangTab] = useState(languages[0])
  const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
  const [communicationMethod, setCommunicationMethod] = useState('')
  const textAreaInputRef = useRef()
  const subjectInputRef = useRef()

  const organization = useSelector((state) =>
    state.authReducer.user.organization ? state.authReducer.user.organization : {}
  )

  const getSequenceList = Form.useWatch('auto_reply_items', form)

  useEffect(() => {
    setSelectedLangTab(languages[0])
  }, [])

  useEffect(() => {
    setCommunicationMethod(selectedAiBotMessage.send_via)
  }, [selectedAiBotMessage.id])

  const selectedUnit = useDynamicWatch(
    ['auto_reply_items', field, 'time_unit', 'unit'],
    form
  )

  const isImmediately = useMemo(() => selectedUnit === IMMEDIATELY, [selectedUnit])

  useEffect(() => {
    const messageContent = selectedAiBotMessage
      ? selectedAiBotMessage.text[selectedLangTab] ||
        (selectedAiBotMessage.subject &&
          selectedAiBotMessage.subject[selectedLangTab])
      : ''
    const messageLength = messageContent ? messageContent.length : 0
    setCurrentCursorPosition(messageLength)
  }, [selectedAiBotMessage.id, selectedLangTab])

  const handleClickVariable = (variable, isSubject = false) => {
    const autoMessagesList = form.getFieldValue('auto_reply_items')
    const fieldKey = isSubject ? 'subject' : 'text'
    const currentText = autoMessagesList[selectedIndex][fieldKey][selectedLangTab]
    const newText = [
      currentText && currentText.slice(0, currentCursorPosition),
      variable,
      currentText && currentText.slice(currentCursorPosition),
    ].join('')

    autoMessagesList[selectedIndex][fieldKey][selectedLangTab] = newText
    form.setFieldsValue({ auto_reply_items: autoMessagesList })
    setCurrentCursorPosition(currentCursorPosition + variable.length)

    const inputRef = isSubject ? subjectInputRef : textAreaInputRef
    inputRef.current &&
      inputRef.current.autoFocus(currentCursorPosition + variable.length)
  }

  const radioOptions = [
    {
      label: getText('WORD_SMS_UC'),
      value: SMS,
      icon: (
        <SVGOrgSMSIcon color={communicationMethod === SMS ? '#FFFFFF' : '#5B66EA'} />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
    {
      label: getText('WORD_EMAIL'),
      value: EMAIL,
      icon: (
        <SVGOrgEmailIcon
          color={communicationMethod === EMAIL ? '#FFFFFF' : '#5B66EA'}
        />
      ),
      disabled:
        (sequenceData?.type === NIGHT || type === NIGHT) && selectedIndex === 1,
    },
  ]

  const listVariables = [
    {
      label: 'customer_first_name',
      value: '{{customer_first_name}}',
    },
    {
      label: 'customer_last_name',
      value: '{{customer_last_name}}',
    },
    {
      label: 'organization_name',
      value: '{{organization_name}}',
    },
    {
      label: 'location_name',
      value: '{{location_name}}',
    },
    {
      label: 'touchpoint',
      value: '{{touchpoint}}',
    },
    {
      label: 'year',
      value: '{{year}}',
    },
    {
      label: 'make',
      value: '{{make}}',
    },
    {
      label: 'model',
      value: '{{model}}',
    },
    {
      label: 'VIN',
      value: '{{VIN}}',
    },
    {
      label: 'trade_year',
      value: '{{trade_year}}',
    },
    {
      label: 'trade_make',
      value: '{{trade_make}}',
    },
    {
      label: 'trade_model',
      value: '{{trade_model}}',
    },
    {
      label: 'trade_VIN',
      value: '{{trade_VIN}}',
    },
    {
      label: 'offer_amount',
      value: '{{offer_amount}}',
    },
    {
      label: 'price_estimate',
      value: '{{price_estimate}}',
    },
  ]

  const langOptions = languages.map((lang) => {
    return {
      label: getLongName(lang),
      value: lang,
    }
  })

  const onReciveAiBotVideo = useCallback(
    (data) => {
      if (data.videoUpload.status === 'VIDEO_PROCESSING_SUCCEEDED') {
        let auto_reply_items = form.getFieldValue('auto_reply_items')
        let find = auto_reply_items.find(
          (item) => item._id === data.videoUpload.targetId
        )
        if (find) {
          Object.assign(find, {
            _video_upload_id: data.videoUpload._id,
            remove_video: false,
            loading: false,
          })
          form.setFieldsValue({ auto_reply_items })
          setSelectedAiBotMessage(find)
        }
      }
    },
    [form]
  )

  useSocket(
    `private-organization=${organization._id}`,
    'VIDEO_UPLOAD_STATUS_CHANGED',
    onReciveAiBotVideo
  )

  const timeUnitsOptions = [
    ...(sequenceData?.type !== NIGHT && type !== NIGHT
      ? [
          {
            label: getText('WORD_DAYS').toLowerCase(),
            value: DAYS,
          },
          {
            label: getText('WORD_HOURS'),
            value: HOURS,
          },
        ]
      : []),
    {
      label: getText('WORD_MINUTES'),
      value: MINUTES,
    },
    ...(selectedAiBotMessage.isFirstItem || selectedAiBotMessage.isSecondMsg
      ? [
          {
            label: getText('WORD_IMMEDIATELY'),
            value: IMMEDIATELY,
          },
        ]
      : []),
  ]

  const handleUpload = async (url) => {
    if (url.mediaContentType.includes('video')) {
      let newObj = {
        videoUploadId: url._id,
        status: 'succeeded',
      }
      let resultPost = await uploadAndFetchPOStFile('/videos/process-video', newObj)
      if (!resultPost.success) {
        notifyError(getTextServerError(resultPost.errMsg))
      }
    } else {
      let auto_reply_items = form.getFieldValue('auto_reply_items')
      let find = auto_reply_items.find((item) => item._id === url._id)
      if (find) {
        Object.assign(find, {
          loading: false,
          media: [
            {
              mediaContentType: url.mediaContentType,
              mediaUrl: url.mediaUrl,
              url: url.url,
            },
          ],
        })
        form.setFieldsValue({
          auto_reply_items: auto_reply_items,
        })
        setSelectedAiBotMessage(find)
      }
    }
  }

  const handleStartUpload = (url) => {
    let auto_reply_items = form.getFieldValue('auto_reply_items')
    let find = auto_reply_items.find((item) => item._id === url._id)
    if (find) {
      Object.assign(find, { loading: true })
      form.setFieldsValue({
        auto_reply_items: auto_reply_items,
      })
      setSelectedAiBotMessage(find)
    }
  }

  const handleChangeStatus = (_, obj) => {
    let auto_reply_items = form.getFieldValue('auto_reply_items')
    let find = auto_reply_items.find((item) => item._id === obj.targetId)
    if (find) {
      Object.assign(find, { loading: true })
      form.setFieldsValue({
        auto_reply_items: auto_reply_items,
      })
      setSelectedAiBotMessage(find)
    }
  }

  const handleDelete = () => {
    let getFieldItem = form
      .getFieldValue('auto_reply_items')
      .find((item) => item._id === selectedAiBotMessage._id)
    if (getFieldItem._video_upload_id) {
      getFieldItem.remove_video = true
      delete getFieldItem._video_upload_id
    }
    if (getFieldItem.media.length > 0) {
      getFieldItem.media = []
    }
    form.setFieldsValue({
      auto_reply_items: form.getFieldValue('auto_reply_items'),
    })
    setSelectedAiBotMessage(getFieldItem)
  }

  const getUploadedItemObj = () => {
    const obj = { type: '', url: '' }

    if (selectedAiBotMessage?._video_upload_id) {
      obj.type = VIDEO
    }

    if (selectedAiBotMessage?.media?.length) {
      if (selectedAiBotMessage?.media[0].mediaContentType === APPLICATION_PDF) {
        obj.type = PDF
      } else {
        obj.type = IMAGE
        obj.url = selectedAiBotMessage?.media[0].mediaUrl
      }
    }

    return obj
  }

  const uploadedItemObj = getUploadedItemObj()

  return (
    <React.Fragment>
      <MyDefaultBlockSubtitle
        subtitle={getText('HOW_WOULD_YOU_LIKE_TO_SEND_THIS_MESSAGE')}
      />
      <MyDefaultRadioButton
        isForm
        name={['auto_reply_items', field, 'send_via']}
        radioOptions={radioOptions}
        onChange={(e) => {
          if (
            getSequenceList?.length === 2 &&
            (sequenceData?.type === NIGHT || type === NIGHT) &&
            selectedIndex === 0
          ) {
            form.setFieldValue(
              ['auto_reply_items', 1, 'send_via'],
              e.target.value === SMS ? EMAIL : SMS
            )
          }
          setCommunicationMethod(e.target.value)
        }}
      />
      <MyDefaultSendFollowUp
        vertical={['mobile', 'tabletPortrait'].includes(screenSize)}
        disableMargin={false}
        beforeText={
          selectedAiBotMessage.isFirstItem
            ? getText('TEXT_FIRST_AUTO_MESSAGE_FULL')
            : getText('TEXT_SEND_FOLLOWUP')
        }
        afterText={getText(
          triggeredOn === TOUCHPOINT
            ? 'TEXT_AFTER_RECEIVING_LEAD_FULL'
            : 'TEXT_AFTER_CUSTOMER_LAST_SESSION_FULL'
        )}
        options={timeUnitsOptions || []}
        inputName={['auto_reply_items', field, 'time_unit', 'value']}
        selectName={['auto_reply_items', field, 'time_unit', 'unit']}
        hideNumber={isImmediately}
        inputInitialValue={selectedAiBotMessage?.time_unit?.value}
        selectInitialValue={{
          unit: isImmediately ? IMMEDIATELY : selectedAiBotMessage?.time_unit?.unit,
        }}
        min={!isImmediately ? 1 : 0}
        max={
          !isImmediately && (sequenceData?.type === NIGHT || type === NIGHT) ? 15 : 0
        }
        maxMessage={
          (sequenceData?.type === NIGHT || type === NIGHT) &&
          getText('MSG_MAXIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
        }
        minMessage={
          (sequenceData?.type === NIGHT || type === NIGHT) &&
          getText('MSG_MINIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
        }
        errorMessage={
          sequenceData?.type === NIGHT || type === NIGHT
            ? getText('MSG_MINIMUM_DELAY_FOR_AFTER_HOUR_SEQUENCE')
            : getText('ERR_PLEASE_FILL_TIME_UNIT')
        }
      />
      <MyDefaultBlockSubtitle
        subtitle={
          communicationMethod === EMAIL
            ? getText('WORD_EMAIL')
            : getText('WORD_SMS_UC')
        }
        rightContent={
          <MyDefaultRadioTabs
            radioOptions={langOptions}
            value={selectedLangTab}
            onChange={(lang) => {
              setSelectedLangTab(lang)
            }}
          />
        }
      />
      {communicationMethod === EMAIL && (
        <React.Fragment>
          <MyDefaultInputText
            isForm
            placeholder={getText('WORD_SUBJECT')}
            initialValue={
              selectedLangTab &&
              selectedAiBotMessage.subject &&
              selectedAiBotMessage.subject[selectedLangTab]
                ? {
                    [selectedLangTab]: selectedAiBotMessage.subject
                      ? selectedAiBotMessage.subject[selectedLangTab]
                      : '',
                  }
                : ''
            }
            name={['auto_reply_items', field, 'subject', selectedLangTab]}
            onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
            onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
          />
          <MyDefaultAddVariablesInMessage
            onClick={(variable) => handleClickVariable(variable, true)}
            data={listVariables}
          />
        </React.Fragment>
      )}
      <MyDefaultTextarea
        isForm
        ref={textAreaInputRef}
        name={['auto_reply_items', field, 'text', selectedLangTab]}
        initialValue={{
          [selectedLangTab]: selectedAiBotMessage.text[selectedLangTab],
        }}
        placeholder={getText('WORD_MESSAGE')}
        onClick={(e) => setCurrentCursorPosition(e.target.selectionStart)}
        onKeyUp={(e) => setCurrentCursorPosition(e.target.selectionStart)}
        onChange={(e) => setCurrentCursorPosition(e.target.selectionStart)}
      />
      <MyDefaultAddVariablesInMessage
        onClick={(variable) => handleClickVariable(variable)}
        data={listVariables}
        disableMargin={communicationMethod !== SMS}
      />
      {communicationMethod === SMS && (
        <MyDefaultUploadWrapperBig
          disableMargins
          onStartUpload={handleStartUpload}
          targetType={selectedAiBotMessage.isFirstItem ? 'sequence' : 'autoreply'}
          targetId={selectedAiBotMessage._id}
          showUploadList={false}
          loading={selectedAiBotMessage.loading}
          mediaCount={1}
          onUpload={handleUpload}
          beforeUploadProps
          onChangeStatus={handleChangeStatus}
          listType={'text'}
          mediaTypes={MEDIATYPES_IMG_VIDEO_PDF}
          disabled={sequenceData === undefined || !selectedAiBotMessage._id}
          customType={[{ size: 1, type: 'application/pdf' }]}
          title={
            Boolean(sequenceData === undefined || !selectedAiBotMessage._id) &&
            getText(
              'TEXT_YOU_ONLY_ABLE_UPLOAD_VIDEO_ONCE_THE_SEQUENCE_HAS_BEEN_SAVED'
            )
          }
          onDelete={handleDelete}
          uploadedItemObj={uploadedItemObj}
        />
      )}
    </React.Fragment>
  )
}

export default AutoReplyPanel
