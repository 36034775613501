import React, { useState, useEffect } from 'react'
import { Flex } from 'antd'
import Div from '../../Div/Div'
import MyDefaultSelect from '../../MyDefaultSelect/MyDefaultSelect'
import './MyDefaultCustomTimePicker.scss'

const MyDefaultCustomTimePicker = ({
  hourValueInit,
  minuteValueInit,
  systemHourInit,
  onChange,
  index,
  disabled,
}) => {
  const [minuteValue, setminuteValue] = useState(minuteValueInit)
  const [hourValue, sethourValue] = useState(hourValueInit)
  const [systemHour, setsystemHour] = useState(systemHourInit)

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target

    const fieldIndex = name.match(/-(\d+)(?:_|$)/)

    let fieldIntIndex = parseInt(fieldIndex[1], 10) + 1

    if (value.length >= maxLength) {
      // if (fieldIntIndex < 2) {
      const nextfield = document.querySelector(
        `input[name=field-${fieldIntIndex}${index ? `_${index}` : ''}]`
      )
      if (nextfield !== null) {
        nextfield.focus()
      }
      // }
    }
  }

  useEffect(() => {
    onChange && onChange(`${hourValue}:${minuteValue} ${systemHour}`)
  }, [minuteValue, hourValue, systemHour])

  return (
    <Flex className='my_default_custom_time_picker' gap={8}>
      <Flex className='my_default_custom_time_picker--inputs' align='center'>
        <input
          name={`field-1${index ? `_${index}` : ''}`}
          value={hourValue}
          type='number'
          inputMode='numeric'
          disabled={disabled}
          onChange={(e) => {
            if (e.target.value.length === 3) {
              return false
            }
            if (e.target.value > 12 || e.target.value < 0) {
              return false
            }
            sethourValue(e.target.value)
            handleChange(e)
          }}
          onBlur={(e) => {
            if (e.target.value.length === 1) {
              sethourValue(e.target.value.toString().padStart(2, '0'))
              return
            }
          }}
          maxLength={2}
        />
        <Div className='point'>:</Div>
        <input
          name={`field-2${index ? `_${index}` : ''}`}
          value={minuteValue}
          type='number'
          inputMode='numeric'
          disabled={disabled}
          onChange={(e) => {
            if (e.target.value.length === 3) {
              return false
            }
            if (e.target.value > 59 || e.target.value < 0) {
              return false
            }
            setminuteValue(e.target.value)
            handleChange(e)
          }}
          onBlur={(e) => {
            if (e.target.value.length === 1) {
              setminuteValue('0' + e.target.value)
            }
          }}
          maxLength={2}
        />
      </Flex>
      <MyDefaultSelect
        selectStyle={{ minWidth: 86 }}
        value={systemHour}
        disabled={disabled}
        options={[
          {
            label: 'AM',
            value: 'AM',
          },
          {
            label: 'PM',
            value: 'PM',
          },
        ]}
        onSelectItem={(item) => {
          setsystemHour(item.value)
        }}
      />
    </Flex>
  )
}

export default MyDefaultCustomTimePicker
