import React from 'react'
import Icon from '@ant-design/icons'
import { getText } from '../../lang'
import Div from '../../components/Div/Div'

const multipleLineText = (
  title,
  x,
  y,
  maxWidth = 100,
  lineHeight = '18px',
  textAlign = 'right',
  fontSize = 14
) => {
  return (
    <foreignObject x={x} y={y} width='150' height='100'>
      <Div
        xmlns='http://www.w3.org/1999/xhtml'
        style={{
          maxWidth: maxWidth,
          lineHeight: lineHeight,
          textAlign: textAlign,
          fontSize: fontSize,
        }}
      >
        {title}
      </Div>
    </foreignObject>
  )
}

const SVGIcon = ({
  profiles_messaged,
  avg_per_user_count,
  engaged_clients,
  ai_bot_messages,
  ai_bot_percentage,
  appointment_conversion_count,
}) => {
  return (
    <svg
      width='1260'
      height='288'
      viewBox='0 0 1260 288'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M192 139H272.61C295.626 139 317.737 130.032 334.25 114V114C350.763 97.9676 372.874 89 395.89 89H1141.5'
        stroke='url(#paint0_linear_17107_30059)'
        strokeWidth='50'
      />
      <path
        opacity='0.2'
        d='M192 139H271.75C295.02 139 317.399 130.049 334.25 114V114C351.101 97.9515 373.48 89 396.75 89H1116'
        stroke='white'
        strokeWidth='1.5'
      />
      <rect
        y='29'
        width='220'
        height='220'
        rx='110'
        fill='#24AADF'
        fillOpacity='0.12'
      />
      <circle cx='110' cy='139' r='89.375' fill='#24AADF' />
      <circle cx='110' cy='139' r='82' stroke='#24AADF' />
      <path
        d='M96.166 116.333H122.833'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M106.5 92.3334V116.333H112.5V92.3334C112.5 90.8667 111.9 89.6667 110.1 89.6667H108.9C107.1 89.6667 106.5 90.8667 106.5 92.3334Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M97.5 100.333V116.333H102.833V100.333C102.833 98.8667 102.3 97.6667 100.7 97.6667H99.6333C98.0333 97.6667 97.5 98.8667 97.5 100.333Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.166 107V116.333H121.499V107C121.499 105.533 120.966 104.333 119.366 104.333H118.299C116.699 104.333 116.166 105.533 116.166 107Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <text
        x='108.1534'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={14}
        fontWeight={400}
        y='138.399'
        fill='white'
      >
        {getText('WORD_LEAD_VOLUME')}
      </text>

      <text
        x='108.1534'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={32}
        y='180'
        fill='white'
      >
        {profiles_messaged}
      </text>
      <text
        x='496.798'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={24}
        y='19.23'
        fill='white'
      >
        {ai_bot_messages}
      </text>
      <text
        x='535.798'
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={14}
        y='10.23'
        fill='white'
      >
        ({getText('WORD_SMS_UC')}, {getText('WORD_EMAIL')},
      </text>
      <text
        x='535.798'
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={14}
        y='30.23'
        fill='white'
      >
        {getText('WORD_SOCIAL_FB')}, {getText('WORD_SOCIAL_INSTAGRAM')})
      </text>
      <text
        x='1116.798'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={24}
        y='22.63'
        fill='white'
      >
        {appointment_conversion_count}
      </text>
      <rect
        x='1068'
        y='39'
        width='100'
        height='100'
        rx='50'
        fill='#F6BA3A'
        fillOpacity='0.12'
      />
      <circle cx='1118' cy='89' r='40.625' fill='#E7AB2B' />
      <circle cx='1118' cy='89' r='37' stroke='#FFD479' />
      <path
        d='M1112.67 75.6667V79.6667'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1123.33 75.6667V79.6667'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1106.67 85.1201H1129.33'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1130 84.3334V95.6667C1130 99.6667 1128 102.333 1123.33 102.333H1112.67C1108 102.333 1106 99.6667 1106 95.6667V84.3334C1106 80.3334 1108 77.6667 1112.67 77.6667H1123.33C1128 77.6667 1130 80.3334 1130 84.3334Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1122.93 91.2668H1122.94'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1122.93 95.2668H1122.94'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1117.99 91.2668H1118.01'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1117.99 95.2668H1118.01'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1113.06 91.2668H1113.07'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1113.06 95.2668H1113.07'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {multipleLineText(getText('TEXT_MESSAGES_SENT'), 354, 72, 80)}
      {multipleLineText(getText('TEXT_AVG_MESSAGE_SENT_PER_USER'), 315, 194, 120)}
      {multipleLineText(getText('WORD_APPOINTMENTS_CONVERSION'), 974, 72, 80)}
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M448 203L448 89L548 89L548 203L448 203Z'
        fill='#B4EAFF'
      />
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M467 203L467 86L529 86L529 203L467 203Z'
        fill='#B4EAFF'
      />
      <rect
        x='448'
        y='39'
        width='100'
        height='100'
        rx='50'
        fill='#B4EAFF'
        fillOpacity='0.12'
      />
      <circle cx='498' cy='89' r='40.625' fill='#24AADF' />
      <circle cx='498' cy='89' r='37' stroke='#36BAEE' />
      <path
        d='M491.866 81.4266L503.186 77.6533C508.266 75.96 511.026 78.7333 509.346 83.8133L505.573 95.1333C503.04 102.747 498.88 102.747 496.346 95.1333L495.226 91.7733L491.866 90.6533C484.253 88.12 484.253 83.9733 491.866 81.4266Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M495.48 91.2L500.254 86.4133'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='498' cy='209' r='50' fill='#3B3F6A' />
      <circle cx='498' cy='209' r='49.5' stroke='#5C97AD' strokeOpacity='0.3' />
      <path
        d='M509.863 180.36C506.102 178.802 502.071 178 498 178C493.929 178 489.898 178.802 486.137 180.36C482.376 181.918 478.958 184.201 476.08 187.08C473.201 189.958 470.918 193.376 469.36 197.137C467.802 200.898 467 204.929 467 209C467 213.071 467.802 217.102 469.36 220.863C470.918 224.624 473.201 228.042 476.08 230.92C478.958 233.799 482.376 236.082 486.137 237.64C489.898 239.198 493.929 240 498 240C502.071 240 506.102 239.198 509.863 237.64C513.624 236.082 517.042 233.799 519.92 230.92C522.799 228.042 525.082 224.624 526.64 220.863C528.198 217.102 529 213.071 529 209C529 204.929 528.198 200.898 526.64 197.137C525.082 193.376 522.799 189.958 519.92 187.08C517.042 184.201 513.624 181.918 509.863 180.36Z'
        fill='#24AADF'
      />
      <path
        d='M498 178C502.071 178 506.102 178.802 509.863 180.36C513.624 181.918 517.042 184.201 519.92 187.08C522.799 189.958 525.082 193.376 526.64 197.137C528.198 200.898 529 204.929 529 209C529 213.071 528.198 217.102 526.64 220.863C525.082 224.624 522.799 228.042 519.92 230.92C517.042 233.799 513.624 236.082 509.863 237.64C506.102 239.198 502.071 240 498 240C493.929 240 489.898 239.198 486.137 237.64C482.376 236.082 478.958 233.799 476.08 230.92C473.201 228.042 470.918 224.624 469.36 220.863C467.802 217.102 467 213.071 467 209C467 204.929 467.802 200.898 469.36 197.137C470.918 193.376 473.201 189.958 476.08 187.08C478.958 184.201 482.376 181.918 486.137 180.36C489.898 178.802 493.929 178 498 178Z'
        fill='#24AADF'
      />
      <text
        x='498.106'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize='16'
        fontWeight='500'
        y='210.399'
        fill='white'
      >
        {avg_per_user_count}
      </text>
      <path
        d='M498 169C503.253 169 508.454 170.035 513.307 172.045C518.16 174.055 522.57 177.001 526.284 180.716C529.999 184.43 532.945 188.84 534.955 193.693C536.965 198.546 538 203.747 538 209C538 214.253 536.965 219.454 534.955 224.307C532.945 229.16 529.999 233.57 526.284 237.284C522.57 240.999 518.16 243.945 513.307 245.955C508.454 247.965 503.253 249 498 249C492.747 249 487.546 247.965 482.693 245.955C477.84 243.945 473.43 240.999 469.716 237.284C466.001 233.57 463.055 229.16 461.045 224.307C459.035 219.454 458 214.253 458 209C458 203.747 459.035 198.546 461.045 193.693C463.055 188.84 466.001 184.43 469.716 180.716C473.43 177.001 477.84 174.055 482.693 172.045C487.546 170.035 492.747 169 498 169L498 169Z'
        stroke='#48547A'
        strokeWidth='10'
      />
      <text
        x='807.106'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize='24'
        fontWeight='500'
        y='21.2'
        fill='white'
      >
        {engaged_clients}
      </text>
      {multipleLineText(getText('WORD_REPLY_SUCCSESS_RATE'), 647, 194)}
      {multipleLineText(getText('WORD_ENGAGED_CLIENTS'), 668, 72, 80)}
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M758 205L758 90L858 90L858 205L758 205Z'
        fill='#B4EAFF'
      />
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M777 205L777 88L839 88L839 205L777 205Z'
        fill='#B4EAFF'
      />
      <circle cx='808' cy='209' r='50' fill='#3B3F6A' />
      <circle cx='808' cy='209' r='49.5' stroke='#5C97AD' strokeOpacity='0.3' />
      <path
        d='M819.863 180.36C816.102 178.802 812.071 178 808 178C803.929 178 799.898 178.802 796.137 180.36C792.376 181.918 788.958 184.201 786.08 187.08C783.201 189.958 780.918 193.376 779.36 197.137C777.802 200.898 777 204.929 777 209C777 213.071 777.802 217.102 779.36 220.863C780.918 224.624 783.201 228.042 786.08 230.92C788.958 233.799 792.376 236.082 796.137 237.64C799.898 239.198 803.929 240 808 240C812.071 240 816.102 239.198 819.863 237.64C823.624 236.082 827.042 233.799 829.92 230.92C832.799 228.042 835.082 224.624 836.64 220.863C838.198 217.102 839 213.071 839 209C839 204.929 838.198 200.898 836.64 197.137C835.082 193.376 832.799 189.958 829.92 187.08C827.042 184.201 823.624 181.918 819.863 180.36Z'
        fill='#19BE83'
      />
      <path
        d='M808 178C812.071 178 816.102 178.802 819.863 180.36C823.624 181.918 827.042 184.201 829.92 187.08C832.799 189.958 835.082 193.376 836.64 197.137C838.198 200.898 839 204.929 839 209C839 213.071 838.198 217.102 836.64 220.863C835.082 224.624 832.799 228.042 829.92 230.92C827.042 233.799 823.624 236.082 819.863 237.64C816.102 239.198 812.071 240 808 240C803.929 240 799.898 239.198 796.137 237.64C792.376 236.082 788.958 233.799 786.08 230.92C783.201 228.042 780.918 224.624 779.36 220.863C777.802 217.102 777 213.071 777 209C777 204.929 777.802 200.898 779.36 197.137C780.918 193.376 783.201 189.958 786.08 187.08C788.958 184.201 792.376 181.918 796.137 180.36C799.898 178.802 803.929 178 808 178Z'
        fill='#19BE83'
      />
      <text
        x='806.406'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={16}
        fontWeight={500}
        y='210.399'
        fill='white'
      >
        {ai_bot_percentage}%
      </text>
      <path
        d='M808 169C813.253 169 818.454 170.035 823.307 172.045C828.16 174.055 832.57 177.001 836.284 180.716C839.999 184.43 842.945 188.84 844.955 193.693C846.965 198.546 848 203.747 848 209C848 214.253 846.965 219.454 844.955 224.307C842.945 229.16 839.999 233.57 836.284 237.284C832.57 240.999 828.16 243.945 823.307 245.955C818.454 247.965 813.253 249 808 249C802.747 249 797.546 247.965 792.693 245.955C787.84 243.945 783.43 240.999 779.716 237.284C776.001 233.57 773.055 229.16 771.045 224.307C769.035 219.454 768 214.253 768 209C768 203.747 769.035 198.546 771.045 193.693C773.055 188.84 776.001 184.43 779.716 180.716C783.43 177.001 787.84 174.055 792.693 172.045C797.546 170.035 802.747 169 808 169L808 169Z'
        stroke='#48547A'
        strokeWidth='10'
      />
      <defs>
        <linearGradient id='progressBarGradient' x1='1' y1='1' x2='0' y2='0'>
          <stop stopColor='#19BE83' />
          <stop offset='1' stopColor='#00E4C9' />
        </linearGradient>
      </defs>
      <circle
        stroke='url(#progressBarGradient)'
        fill='none'
        strokeWidth={8}
        strokeDasharray={'251.328'}
        strokeLinecap={'round'}
        strokeDashoffset={`calc(251.328 * (1 - (60 / 100)))`}
        cx='808'
        transform='rotate(-90 808 209)'
        cy='209'
        r='40'
      ></circle>
      <rect
        x='758'
        y='41'
        width='100'
        height='100'
        rx='50'
        fill='#86FFDE'
        fillOpacity='0.12'
      />
      <circle cx='808' cy='91' r='40.625' fill='#19BE83' />
      <circle cx='808' cy='91' r='37' stroke='#2FE6B4' />
      <path
        d='M804.213 89.4934C804.08 89.4801 803.92 89.4801 803.773 89.4934C800.6 89.3867 798.08 86.7867 798.08 83.5867C798.08 80.3201 800.72 77.6667 804 77.6667C807.267 77.6667 809.92 80.3201 809.92 83.5867C809.907 86.7867 807.387 89.3867 804.213 89.4934Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M813.88 80.3333C816.467 80.3333 818.547 82.4266 818.547 84.9999C818.547 87.5199 816.547 89.5733 814.054 89.6666C813.947 89.6533 813.827 89.6533 813.707 89.6666'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M797.547 94.4133C794.32 96.5733 794.32 100.093 797.547 102.24C801.214 104.693 807.227 104.693 810.894 102.24C814.12 100.08 814.12 96.5599 810.894 94.4133C807.24 91.9733 801.227 91.9733 797.547 94.4133Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M816.453 101.667C817.413 101.467 818.32 101.08 819.066 100.507C821.146 98.9467 821.146 96.3734 819.066 94.8134C818.333 94.2534 817.44 93.8801 816.493 93.6667'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g filter='url(#filter0_b_17107_30059)'>
        <rect
          x='870'
          y='191'
          width='142'
          height='36'
          rx='6'
          fill='url(#paint2_linear_17107_30059)'
          fillOpacity='0.4'
        />
        <rect
          x='870.5'
          y='191.5'
          width='141'
          height='35'
          rx='5.5'
          stroke='#EAECF0'
          strokeOpacity='0.2'
        />
        {multipleLineText(
          getText('WORD_INDUSTRY_AVERAGE_ENGAGEMENT'),
          880,
          192,
          98,
          '16px',
          'left',
          10
        )}
        <text
          x='986'
          dominantBaseline='middle'
          textAnchor='middle'
          fontSize={14}
          fontWeight={500}
          y='210.399'
          fill='white'
        >
          44%
        </text>
      </g>
      <defs>
        <filter
          id='filter0_b_17107_30059'
          x='830'
          y='151'
          width='222'
          height='116'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='20' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_17107_30059'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_17107_30059'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_17107_30059'
          x1='192'
          y1='126'
          x2='1112'
          y2='126'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5B66EA' />
          <stop offset='0.341725' stopColor='#16B9DC' />
          <stop offset='0.675' stopColor='#19BE83' />
          <stop offset='0.985' stopColor='#FFA51F' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_17107_30059'
          x1='808'
          y1='169'
          x2='840.5'
          y2='235.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#19BE83' />
          <stop offset='1' stopColor='#00E4C9' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_17107_30059'
          x1='1005.15'
          y1='192.328'
          x2='888.244'
          y2='241.638'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#443F91' />
          <stop offset='1' stopColor='#3E3DAE' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SVGDashSequenceBarIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashSequenceBarIcon
