import React, {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { Flex, Tag } from 'antd'
import { debounce } from 'lodash'
import statActions from '../../../store/modules/statActions'
import { getText } from '../../../lang'
import { formatDateTime } from '../../../utils'
import SVGSearch from '../../../icons/SVG/SVGSearch'
import MatTable from '../../../components/MatTable'
import ProfileLetters from '../../../components/ProfileLetters'
import { CUSTOM, ORG, TABLE_DEFAULT_ROW_HEIGHT } from '../../../devOptions'
import { notifyError } from '../../../utils/Notify'
import { DashboardContext } from '../Dashboard'
import Div from '../../../components/MatDiv/Div'
import ListLoadMore from '../../../components/ListLoadMore'
import InputTextSecondary from '../../../components/InputText/InputTextSecondary'
import MatSelectSecondary from '../../../components/MatSelect/MatSelectSecondary'
import utilNumber from '../../../utils/utilNumber'
import DashboardInfoTooltip from '../DashboardComponents/DashboardInfoTooltip/DashboardInfoTooltip'
import './DashboardUsers.scss'

const UserType = ({ isAdmin, isManager, isSuperAdmin, isSupportAgent }) => {
  const setUserType = () => {
    if (isSuperAdmin) {
      return {
        color: 'var(--red)',
        title: getText('WORD_ROLE_SUPER_ADMIN'),
      }
    } else if (isAdmin) {
      return {
        color: 'var(--green)',
        title: getText('WORD_ROLE_ADMIN'),
      }
    } else if (isManager) {
      return {
        color: 'var(--blue)',
        title: getText('WORD_ROLE_MANAGER'),
      }
    } else if (isSupportAgent) {
      return {
        color: 'var(--mainColor)',
        title: getText('WORD_ROLE_SUPPORT'),
      }
    }
  }

  const userType = setUserType()
  return (
    <Tag
      className='dashboard_users_page--user-type'
      color={userType?.color || 'transparent'}
    >
      {userType?.title || ''}
    </Tag>
  )
}

const TableTitleWithTooltip = ({ title, tooltipTitle }) => {
  return (
    <Flex align='center'>
      {title}
      <DashboardInfoTooltip title={tooltipTitle} />
    </Flex>
  )
}

const DashboardUsers = forwardRef((_, ref) => {
  const {
    selectedRange,
    selectedDataType,
    selectCustomRange,
    dashboardUserPageRef,
  } = useContext(DashboardContext)

  const location = useSelector((state) => {
    return state.authReducer.user.location
  })

  const [loading, setLoading] = useState(false)
  const [usersData, setUsersData] = useState({
    list: [],
    total: 0,
  })
  const [page, setPage] = useState(0)
  const [searchDebounce, setSearchDebounce] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [userTypeValue, setUserTypeValue] = useState()

  useImperativeHandle(ref, () => ({
    resetPage: () => {
      setPage(0)
    },
  }))

  const userTypeValueList = [
    {
      label: getText('WORD_ROLE_ADMIN'),
      value: 'isAdmin',
    },
    {
      label: getText('WORD_ROLE_MANAGER'),
      value: 'isManager',
    },
    {
      label: getText('WORD_ROLE_SUPPORT'),
      value: 'isSupportAgent',
    },
  ]

  const columns = [
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_USER_NAME')}
          tooltipTitle={getText('TOOLTIP_USER_USER_NAME')}
        />
      ),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (_, { user_name }) => {
        return (
          <Flex align='center'>
            <ProfileLetters
              className='medium'
              name={Boolean(user_name?.trim()) ? user_name : '-'}
              withoutIcon
            />
            {Boolean(user_name?.trim()) ? user_name : '-'}
          </Flex>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_USER_TYPE')}
          tooltipTitle={getText('TOOLTIP_USER_USER_TYPE')}
        />
      ),
      dataIndex: 'user_type',
      key: 'user_type',
      render: (_, { isAdmin, isManager, isSuperAdmin, isSupportAgent }) => {
        return (
          <UserType
            isAdmin={isAdmin}
            isManager={isManager}
            isSuperAdmin={isSuperAdmin}
            isSupportAgent={isSupportAgent}
          />
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('TEXT_LAST_LOGIN_DATE')}
          tooltipTitle={getText('TOOLTIP_USER_LAST_LOGIN')}
        />
      ),
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      render: (_, { last_login_date }) => {
        return last_login_date ? formatDateTime(last_login_date) : ''
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_APPOINTMENTS_CREATED')}
          tooltipTitle={getText('TOOLTIP_USER_APPOINTMENTS_CREATED')}
        />
      ),
      dataIndex: 'count_appointments',
      key: 'count_appointments',
      render: (_, { count_appointments }) => {
        return (
          <Div style={{ minWidth: 160 }}>
            {utilNumber.formatNumberToK(count_appointments)}
          </Div>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('TEXT_VIDEO_SENT')}
          tooltipTitle={getText('TOOLTIP_USER_VIDEOS_SENT')}
        />
      ),
      dataIndex: 'count_videos_sent',
      key: 'count_videos_sent',
      render: (_, { count_videos_sent }) => {
        return (
          <Div style={{ minWidth: 160 }}>
            {utilNumber.formatNumberToK(count_videos_sent)}
          </Div>
        )
      },
    },
    {
      title: (
        <TableTitleWithTooltip
          title={getText('WORD_SENT_MESSAGES')}
          tooltipTitle={getText('TOOLTIP_USER_SENT_MESSAGES')}
        />
      ),
      dataIndex: 'count_messages_sent',
      key: 'count_messages_sent',
      render: (_, { count_messages_sent }) => {
        return (
          <Div style={{ minWidth: 160 }}>
            {utilNumber.formatNumberToK(count_messages_sent)}
          </Div>
        )
      },
    },
  ]

  const fill = useCallback(async () => {
    if (selectedRange === CUSTOM && !selectCustomRange) {
      // Prevent API call if custom range is not selected
      return
    }
    setLoading(true)
    let locationId = selectedDataType === ORG ? '' : location._id
    const result = await statActions.getUserDashboard(
      selectedRange,
      page,
      TABLE_DEFAULT_ROW_HEIGHT,
      locationId,
      selectCustomRange.start_date,
      selectCustomRange.end_date,
      searchDebounce,
      userTypeValue
    )
    if (result.success) {
      if (result.data?.stats?.length > 0) {
        const newData =
          page === 0 ? result.data.stats : [...usersData.list, ...result.data.stats]
        setUsersData({
          list: newData.map((item, index) => {
            Object.assign(item, {
              key: index,
            })
            return item
          }),
          total: result.data.total_items,
        })
      } else {
        setUsersData({
          list: [],
          total: 0,
        })
      }
    } else {
      notifyError(result.errMsg ? result.errMsg : getText('ERR_GENERAL'))
    }
    setLoading(false)
  }, [
    location._id,
    selectedRange,
    selectedDataType,
    page,
    selectCustomRange,
    searchDebounce,
    userTypeValue,
  ])

  useEffect(() => {
    fill()
  }, [fill])

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchDebounce(options)
    }, 700),
    []
  )

  return (
    <Div className={'dashboard_users_page'}>
      <Flex className='dashboard_users_page--inputs'>
        <InputTextSecondary
          placeholder={getText('WORD_SEARCH')}
          allowClear
          value={searchValue}
          prefix={<SVGSearch />}
          autoComplete={'off'}
          variant={false}
          onChange={(e) => {
            debouncedChange(e.target.value)
            setSearchValue(e.target.value)
          }}
          onClear={() => {
            debouncedChange()
          }}
          onPressEnter={() => {
            debouncedChange(searchValue)
          }}
        />
        <MatSelectSecondary
          secondary
          placeholder={getText('WORD_SELECT_ROLE')}
          value={userTypeValue}
          options={userTypeValueList}
          onSelect={(value) => {
            setPage(0)
            setUserTypeValue(value)
          }}
          showSearch={false}
          onClear={() => {
            setPage(0)
            setUserTypeValue()
          }}
        />
      </Flex>
      <ListLoadMore
        loading={loading}
        totalDataCount={usersData.total}
        onLoadMore={() => {
          if (!loading && usersData.list.length < usersData.total) {
            setLoading(true)
            setPage((page) => page + 1)
          }
        }}
        pageStart={0}
        getScrollParent={() => dashboardUserPageRef?.current || window}
        data={usersData.list}
        customList={
          <MatTable
            columns={columns}
            loading={loading}
            dataSource={usersData.list}
            withoutPagination
            sticky={{
              offsetHeader: -10,
            }}
            tableLayout={'auto'}
            scroll={{
              x: 600,
            }}
          />
        }
      />
    </Div>
  )
})

export default memo(DashboardUsers)
