import React, { memo } from 'react'
import { Card, Col, Divider, Flex, Row, Typography } from 'antd'
import Div from '../../../../components/MatDiv/Div'
import utilNumber from '../../../../utils/utilNumber'
import DashboardInfoTooltip from '../DashboardInfoTooltip/DashboardInfoTooltip'

const DashboardHoursBlock = ({
  number,
  workingHours,
  afterHours,
  icon,
  title,
  tooltipTitle,
  tooltipFirstSubTitle,
  tooltipSecondSubTitle,
  firstSubTitle,
  secondSubTitle,
  onClick,
}) => {
  return (
    // ${number ? 'underline_animation' : ''} This will be added to the className when BE side will be ready
    <Card className={`dashboard_block `}>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        {icon}
        <Div className='dashboard_block--header--right'>
          <span className='underline_animation--text'>
            {title}
            {tooltipTitle && <DashboardInfoTooltip title={tooltipTitle} />}
          </span>
        </Div>
      </Flex>
      <Flex
        align='center'
        style={{ marginLeft: 0 }}
        className={'dashboard_sent_statistics--information'}
      >
        <Typography
          // onClick={
          //   number
          //     ? () => {
          //         onClick && onClick()
          //       }
          //     : null
          // }
          level={1}
        >
          {utilNumber.numberFractions(number)}
        </Typography>
      </Flex>
      <Row className={'dashboard_sent_statistics--information--hours'} wrap={false}>
        <Col>
          <span className='dashboard_sent_statistics--information--hours-text'>
            {firstSubTitle}
            {tooltipFirstSubTitle && (
              <DashboardInfoTooltip title={tooltipFirstSubTitle} />
            )}
          </span>
          <p>{utilNumber.numberFractions(workingHours || 0)}</p>
        </Col>
        <Divider
          type='vertical'
          style={{
            borderInlineStart: '1px solid #38334D',
            height: '48px',
          }}
        />
        <Col>
          <span className='dashboard_sent_statistics--information--hours-text'>
            {secondSubTitle}
            {tooltipSecondSubTitle && (
              <DashboardInfoTooltip title={tooltipSecondSubTitle} />
            )}
          </span>
          <p>{utilNumber.numberFractions(afterHours || 0)}</p>
        </Col>
      </Row>
    </Card>
  )
}

export default memo(DashboardHoursBlock)
