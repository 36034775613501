import moment from 'moment'
import { parsePhoneNumber } from 'libphonenumber-js'
import { capitalize } from 'lodash'
import { getText, getTextServerError } from '../lang'
import authActions from '../store/modules/authActions'
import { DEALER_SOCKET } from '../config/constants'
import userActions from '../store/modules/userActions'
import { notifyError } from './Notify'
import utilDate, {
  AUSTRALIAN_DATE_APPOINTMENT_FORMAT,
  AUSTRALIAN_DATE_FORMAT,
  AUSTRALIAN_DATE_TIME_APPOINTMENT_FORMAT,
  AUSTRALIAN_DATE_TIME_FORMAT,
  FORMAT_DATE_TIME,
  FORMAT_DATE_TIME_PM_AM_FIRST_MONTH,
} from './utilsDate'
import { redirect } from './UrlHelper'

// import React from 'react';

let isDM = false

const WEBVIEW_POST_TYPE = {
  REDIRECT: 1,
  PHONE: 2,
  HARD_RELOAD_MOBILE: 3,
  UPLOAD_BUTTON_CLICKED: 4,
  SHOW_MESSAGE_CENTER: 5,
  CLOSE_MESSAGE_CENTER: 6,
  USER_LOG_OUT: 7,
}

export const MONTH_ORDER = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]
export const MONTH_SHORT_ORDER = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
]

export const MONTH_MAP = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
}

export const isDebugMode = isDM
export const VERIFICATION_RESEND_CODE_TIME = 60

export const DATE_ORIGIN = new Date(0)

export const SELECT_UNSELECT_NONE = ''

export const TABLE_FETCH_PAGE_SIZE = 12
export const GET_DATA_FETCH_SIZE = 50

export const MEDIATYPES_IMG_VIDEO_PDF =
  'application/pdf, image/jpeg, image/jpg, image/png, image/gif, video/mp4, video/mov, video/avi, video/webm, video/quicktime'
export const MEDIATYPES_ONLY_VIDEO =
  'video/mp4, video/mov, video/avi, video/webm, video/quicktime'
export const MEDIATYPES_ONLY_IMAGE = 'image/jpeg, image/jpg, image/png, image/gif'
export const MEDIATYPES_IMAGE_PDF =
  'application/pdf, image/jpeg, image/jpg, image/png, image/gif'
export const ACCEPT_IMAGE_PDF = '.jpeg,.jpg,.png,.pdf,.gif,.HEIC,.heic,.HEIF,.heif'

export const RANGE_PARAMS = ['all_time', 'ytd', 'today', 'mtd', 'last_month']

export const IP_ERROR_MSG =
  'Your organization has IP whitelisting enabled and your current IP is not allowed, please contact your manager.'

export const MainColor = '#5B66EA'

export function formatPhone(phone, shouldLeftPlus = true) {
  let phone_number = phone.replace(/[-+()\s]/g, '')

  if (!phone_number) {
    return ''
  }

  if (shouldLeftPlus) {
    return `+${phone_number}`
  }

  return phone_number
}

export const formatPhoneNumber = (phoneNumber) => {
  //Filter only numbers from the input
  let cleaned = ('' + phoneNumber).replace(/\D/g, '')

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ')' + match[2] + '-' + match[3]
  }

  return phoneNumber
}

export const isAustralianOrg = () => {
  let organization = authActions.getOrganization()
  return organization.twilio_number.startsWith('+61')
}

const australianFormatDate = (date) => {
  return utilDate.getDate(date, AUSTRALIAN_DATE_FORMAT)
}

export function formatDate(date) {
  let organization = authActions.getOrganization()
  if (organization.twilio_number.includes('+61')) {
    return australianFormatDate(date)
  }
  return moment(new Date(date).getTime()).format('MM.DD.YYYY')
}

const australianFormatDateTime = (date) => {
  return utilDate.getDate(date, AUSTRALIAN_DATE_TIME_FORMAT)
}

export function formatDateTime(date) {
  let organization = authActions.getOrganization()
  if (organization.twilio_number.includes('+61')) {
    return australianFormatDateTime(date)
  }
  return moment(new Date(date).getTime()).format('MM.DD.YYYY hh:mm a')
}

export function formatAmount(amount) {
  return `$ ${Number(amount).toFixed(2)}`
}

export function formatTimeAMPM(date) {
  return moment(date).format('LT')
}

export function formatTime(date) {
  date = new Date(date)
  if (date.getFullYear() && date.getTime() !== DATE_ORIGIN.getTime()) {
    return moment(new Date(date).getTime()).format('hh:mm a')
  }
  return '-'
}

export function inputDate(date) {
  date = new Date(date)
  if (date.getFullYear()) {
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (year < 1000) year = '0' + year
    if (year < 100) year = '0' + year
    if (year < 10) year = '0' + year
    let str = `${year}-${month < 10 ? '0' + month : month}-${
      day < 10 ? '0' + day : day
    }`
    return str
  }
  return '-'
}

export function removeSpecialChars(str) {
  const result = str
    .trim()
    .toLowerCase()
    .replace(/[ ~_`@!#$%^&*+=\-[\]\\';,/{}()|\\".:<>?]/g, '')
    .replace(/^-+|-+$/g, '')
  return result
}

export function getLongName(lang) {
  if (lang === 'fr') {
    return getText('LANG_FR')
  } else if (lang === 'sp') {
    return getText('LANG_SP')
  } else if (lang === 'en') {
    return getText('LANG_EN')
  }
  return lang
}

export function pastDaysFrom(days, date = Date.now()) {
  return new Date(date - days * 24 * 60 * 60 * 1000)
}

export function todayDate() {
  return new Date(Date.now())
}

export function getMonthName(value) {
  const months = [
    getText('MONTH_JAN'),
    getText('MONTH_FEB'),
    getText('MONTH_MAR'),
    getText('MONTH_APR'),
    getText('MONTH_MAY'),
    getText('MONTH_JUN'),
    getText('MONTH_JUL'),
    getText('MONTH_AUG'),
    getText('MONTH_SEP'),
    getText('MONTH_OCT'),
    getText('MONTH_NOV'),
    getText('MONTH_DEC'),
  ]
  return months[value]
}

export const getCurrentMonthName = () => {
  return getMonthName(todayDate().getMonth())
}

export const getLastMonthName = () => {
  return getMonthName(todayDate().getMonth() - 1)
}

export function getUnixTime(date) {
  if (date) {
    return Math.round(date.getTime() / 1000)
  }
  return date
}

const australianFormatDateAppointment = (date) => {
  return utilDate.getDate(date, AUSTRALIAN_DATE_APPOINTMENT_FORMAT)
}

export function formatDateAppoinment(date) {
  if (date) {
    let organization = authActions.getOrganization()
    if (organization.twilio_number.includes('+61')) {
      return australianFormatDateAppointment(date)
    }
    return moment(date).locale(authActions.getLanguage()).format('MMM DD, YYYY')
  }
  return date
}

export function formatTimeAppoinment(date) {
  if (date) {
    return moment(date).format('hh:mm a')
  }
  return date
}

const australianFormatDateTimeAppointment = (date) => {
  return utilDate.getDate(date, AUSTRALIAN_DATE_TIME_APPOINTMENT_FORMAT)
}

export function formatDateTimeAppoinment(date) {
  if (date) {
    let organization = authActions.getOrganization()
    if (organization.twilio_number.startsWith('+61')) {
      return australianFormatDateTimeAppointment(date)
    }
    return moment(date)
      .locale(authActions.getLanguage())
      .format('MMM DD, YYYY, hh:mm a')
  }
  return date
}

export function getKValue(value) {
  return value
}

export function getKValueFormat(value) {
  return value
}

export function isValidUrl(string) {
  try {
    new URL(string)
  } catch (_) {
    return false
  }

  return true
}

export function isToday(someDate) {
  if (someDate) {
    someDate = new Date(someDate)
    const today = new Date()
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    )
  }
  return false
}

/**
 * argument--- value (number or string) which should be hide:
 * bodey---  argument parse of string, remove dashes ande space,
 *           hide all characters except for the last 4 characters,
 *           add removed simbols in the appropriate place:
 * return--- hidenned value (string):
 */

export function hidePrivateValue(value) {
  const newValue = `${value}`
  let removeUnusedSymbols = ''
  let indexOfSymbols = {}

  for (let i = 0; i < newValue.length; i++) {
    if (newValue[i] === '-' || newValue[i] === ' ') {
      indexOfSymbols[i] = newValue[i]
      continue
    } else {
      removeUnusedSymbols += newValue[i]
    }
  }

  let hideValue = ''
  for (let i = 0; i < removeUnusedSymbols.length; i++) {
    if (i < removeUnusedSymbols.length - 4) {
      hideValue += 'X'
    } else {
      hideValue += removeUnusedSymbols[i]
    }
  }

  let result = ''
  for (let j = 0; j < hideValue.length; j++) {
    if (indexOfSymbols[j + 1]) {
      result += hideValue[j]
      result += indexOfSymbols[j + 1]
      hideValue = ' ' + hideValue
      j++
    } else {
      result += hideValue[j]
    }
  }
  return result
}

export const TOOLTIP_TEXT =
  'This action is not supported for Facebook messenger conversations just yet'

export const COUNT_OF_TOUCHPOINTS_VIEW = 6

export function isDealerSocketConnected(location = {}) {
  if (location === null) {
    return
  }
  return location.crmType === DEALER_SOCKET
}

export const daysBetween = (date1, date2) => {
  var DSTAdjust = 0
  let oneMinute = 1000 * 60
  var oneDay = oneMinute * 60 * 24
  date1.setHours(0)
  date1.setMinutes(0)
  date1.setSeconds(0)
  date2.setHours(0)
  date2.setMinutes(0)
  date2.setSeconds(0)
  if (date2 > date1) {
    DSTAdjust = (date2.getTimezoneOffset() - date1.getTimezoneOffset()) * oneMinute
  } else {
    DSTAdjust = (date1.getTimezoneOffset() - date2.getTimezoneOffset()) * oneMinute
  }
  var diff = Math.abs(date2.getTime() - date1.getTime()) - DSTAdjust
  return Math.ceil(diff / oneDay)
}

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (
    (userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)) &&
    userAgent.match(/12_/i)
  ) {
    return 'iOS12'
  } else if (
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPod/i)
  ) {
    return 'iOS'
  } else if (userAgent.match(/Android/i)) {
    return 'Android'
  } else {
    return 'unknown'
  }
}

export const checkFormatPhoneNumber = (phoneNumber, slice = false) => {
  if (!phoneNumber) {
    return
  }

  if (phoneNumber.startsWith('+61') || phoneNumber.startsWith('+04')) {
    return `0${phoneNumber.slice(3)}`
  }

  return slice ? phoneNumber.slice(1) : phoneNumber
}

export const checkAustralianPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    if (phoneNumber.startsWith('+61') || phoneNumber.startsWith('+04')) {
      return '04'
    } else {
      return ''
    }
  }
}

export const getInitialCountry = (phoneNumber) => {
  phoneNumber = phoneNumber || authActions?.getOrganization()?.twilio_number
  if (phoneNumber) {
    if (!phoneNumber.startsWith('+')) {
      phoneNumber = `+${phoneNumber}`
    }
    if (phoneNumber.startsWith('+61') || phoneNumber.startsWith('+04')) {
      return 'au'
    } else if (
      (phoneNumber.startsWith('+1') || phoneNumber.startsWith('1')) &&
      phoneNumber.length > 2
    ) {
      return parsePhoneNumber(phoneNumber)?.country?.toLowerCase()
    } else {
      return ''
    }
  }
}

export function hexToRgbA(hex, alpha = 0.1) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    )
  }
}

export function myWebViewPost(msg, type) {
  switch (type) {
    case 'phone':
      type = WEBVIEW_POST_TYPE.PHONE
      break
    case 'HARD_RELOAD_MOBILE':
      type = WEBVIEW_POST_TYPE.HARD_RELOAD_MOBILE
      break
    case 'UPLOAD_BUTTON_CLICKED':
      type = WEBVIEW_POST_TYPE.UPLOAD_BUTTON_CLICKED
      break
    case 'SHOW_MESSAGE_CENTER':
      type = WEBVIEW_POST_TYPE.SHOW_MESSAGE_CENTER
      break
    case 'CLOSE_MESSAGE_CENTER':
      type = WEBVIEW_POST_TYPE.CLOSE_MESSAGE_CENTER
      break
    case 'USER_LOG_OUT':
      type = WEBVIEW_POST_TYPE.USER_LOG_OUT
      break
    default:
      type = WEBVIEW_POST_TYPE.REDIRECT
      break
  }

  window.ReactNativeWebView.postMessage(JSON.stringify({ type: type, msg: msg }))
}

export function addTagBeforeText(item, withTag = false) {
  return item.taggedUsers.reduce((acc, item) => {
    const fullName = !Boolean(item.firstName)
      ? item.lastName
      : !Boolean(item.lastName)
        ? item.firstName
        : item.firstName + ' ' + item.lastName
    const regex = new RegExp(fullName, 'gi')
    return acc.replaceAll(
      regex,
      withTag
        ? `<span style="font-style: italic; font-size: 14px; color: #3F8CFF">${'@' + fullName}</span>`
        : '@' + fullName
    )
  }, item.body)
}

export function getUserRole(isSuperAdmin, isAdmin, isManager, isSupportAgent) {
  if (isSuperAdmin) {
    return 'Super Admin'
  } else if (isAdmin) {
    return 'Admin'
  } else if (isManager) {
    return 'Manager'
  } else if (isSupportAgent) {
    return 'Support agent'
  }
}

export function getCustomerName(conversation) {
  conversation = conversation || {} // null protection
  let authorName =
    (conversation?.receiver?.fullName && conversation.receiver.fullName.trimEnd()) ||
    checkFormatPhoneNumber(conversation.receiver_phone) ||
    conversation.receiver_email ||
    ''

  if (!authorName && conversation.kind === 'gbm') {
    authorName = getText('TITLE_GOOGLE_BUSINESS')
  }

  // TODO in future BE will add kind instagram. For now, it's always messenger
  if (
    !authorName &&
    (conversation.kind === 'messenger' || conversation.kind === 'instagram')
  ) {
    if (conversation.source && conversation.source.includes('IG messenger')) {
      authorName = getText('TITLE_IG_MESSENGER')
    } else {
      authorName = getText('TITLE_FB_MESSENGER')
    }
  }

  return authorName
}

export function phoneNumberFormater(number) {
  return number
}

export function emailFormater(email) {
  const parts = email.split('@')
  const username = parts[0]
  const domain = parts[1]
  const formattedUsername = `${username[0]}${'*'.repeat(username.length - 2)}${
    username[username.length - 1]
  }`
  const lastDotIndex = domain.lastIndexOf('.')
  const formattedDomain = `${domain[0]}${'*'.repeat(
    lastDotIndex - 1
  )}${domain.substring(lastDotIndex - 1)}`
  const formattedEmail = `${formattedUsername}@${formattedDomain}`
  return formattedEmail
}

export function getNameFromFirstLastNames(fullName, firstName, lastName) {
  if (fullName) {
    return fullName
  } else if (firstName && lastName === '') {
    return firstName
  } else if (firstName === '' && lastName) {
    return lastName
  } else if (firstName && lastName) {
    return firstName + ' ' + lastName
  } else {
    return false
  }
}

export async function getPDFFile(url) {
  try {
    const response = await fetch(url)
    const pdfBlob = await response.blob()
    const blobUrl = URL.createObjectURL(pdfBlob)
    const link = document.createElement('a')
    link.href = blobUrl
    link.setAttribute('download', 'document.pdf')
    link.click()
    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Error downloading PDF:', error)
  }
}

export function setMixpanel(
  mixpanel,
  title,
  createdAt,
  userName,
  organizationName,
  locationName,
  touchpoints,
  organization_id,
  location_id,
  user_id
) {
  const isMobileApp = authActions.getIsMobileApp()
  const mixpanelConfig = mixpanel && mixpanel.config
  if (mixpanelConfig && mixpanelConfig.token) {
    const mixpanelSentObject = {
      Date: formatDateTime(createdAt),
      User: userName,
      Organization: organizationName,
      Location: locationName,
      mobileApp: Boolean(isMobileApp),
      mobileDesktop: Boolean(!isMobileApp && window.innerWidth < 480),
      desktop: Boolean(!isMobileApp && window.innerWidth > 480),
      organization_id: organization_id,
      location_id: location_id,
      user_id: user_id,
    }
    if (touchpoints) {
      mixpanelSentObject.Touchpoint = touchpoints
    }
    if (Boolean(isMobileApp)) {
      let device = window.platform
      if (device === 'ios') {
        device = 'iOS'
      } else if ('device' === 'android') {
        device = 'Android'
      }
      mixpanelSentObject['Operating System'] = `Mobile App ${device}`
    }
    mixpanel.track(title, mixpanelSentObject)
  }
}

export function getCustomerPhoneInvlid(conversation) {
  const organization = authActions.getOrganization()
  let isInvalid = false
  conversation = conversation || {}
  if (conversation.receiver && conversation.receiver.phoneStatus) {
    if (conversation.receiver.phoneStatus.invalidUntil !== null) {
      if (
        moment(
          utilDate.getDate(
            conversation.receiver.phoneStatus.invalidUntil,
            FORMAT_DATE_TIME
          )
        ).isAfter(utilDate.getTodayDate(FORMAT_DATE_TIME))
      ) {
        isInvalid = utilDate.getDateTimeUTC(
          conversation.receiver.phoneStatus.invalidUntil,
          organization.twilio_number.includes('+61')
            ? AUSTRALIAN_DATE_TIME_FORMAT
            : FORMAT_DATE_TIME_PM_AM_FIRST_MONTH
        )
      }
    }
  }
  return isInvalid
}

export function getFilteredCount(filterToggleStatuses) {
  const combinedArray = [
    ...filterToggleStatuses.touchpoints,
    ...filterToggleStatuses.users,
    ...filterToggleStatuses.locationFilter,
    ...filterToggleStatuses.tagsFilter,
    ...(filterToggleStatuses.dateFilter.length ? ['matFilterDate'] : []),
    ...(filterToggleStatuses.npsScoreFilter ? ['matNpsScoreFilter'] : []),
    ...(filterToggleStatuses.broadcastFilter ? ['matbroadcastFilter'] : []),
    ...(filterToggleStatuses.fingerprinting ? ['matonlyActivitySequence'] : []),
    ...(filterToggleStatuses.incomingCalls ? ['matinboundCallsFilter'] : []),
    ...(filterToggleStatuses.filterOutgoingCalls ? ['matfilterOutgoingCalls'] : []),
    ...(filterToggleStatuses.superhuman ? ['matSuperhumanFilter'] : []),
    ...(filterToggleStatuses.opportunities ? ['matopportunities'] : []),
  ]
  return combinedArray
}

export async function moveToLoginPage(navigate, redirect, state) {
  await userActions.deleteFCMToken()
  setTimeout(() => {
    authActions.setTokenData({})
    localStorage.clear()
    if (authActions.getIsMobileApp()) {
      myWebViewPost('USER_LOG_OUT', 'USER_LOG_OUT')
    }
    if (redirect) {
      navigate('/login')
    } else {
      navigate('/login', state)
    }
  }, 0)
}

export function hasTrueOrNotEmptyValue(obj) {
  for (const key in obj) {
    if (obj[key] === true) {
      return true
    } else if (Array.isArray(obj[key])) {
      if (obj[key].length > 0) {
        return true
      }
    } else if (Boolean(obj[key])) {
      return true
    }
  }
  return false
}

export function checkPhoneFormatValidationDuringSaving(
  twilio_number,
  callForwardingNumber
) {
  const callForwardingNumbersList = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
  ]
  if (twilio_number.startsWith('+')) {
    twilio_number = twilio_number.slice(1)
  }
  if (callForwardingNumber && callForwardingNumber.startsWith('+')) {
    callForwardingNumber = callForwardingNumber.slice(1)
  }
  if (twilio_number.startsWith('04')) {
    twilio_number = `61${twilio_number.slice(1)}`
  }
  if (
    callForwardingNumber &&
    callForwardingNumbersList.includes(callForwardingNumber.substring(0, 2))
  ) {
    callForwardingNumber = `61${callForwardingNumber.slice(1)}`
  }
  if (
    twilio_number.substring(0, 1) !== '1' &&
    twilio_number.substring(0, 2) !== '61'
  ) {
    notifyError(getTextServerError(getText('ERROR_WRONG_PHONE_FORMAT')))
    return true
  }
  if (
    callForwardingNumber &&
    callForwardingNumber.substring(0, 1) !== '1' &&
    callForwardingNumber &&
    callForwardingNumber.substring(0, 2) !== '61'
  ) {
    notifyError(getTextServerError(getText('ERROR_WRONG_PHONE_FORMAT')))
    return true
  }
  return {
    twilio_number: twilio_number,
    callForwardingNumber: callForwardingNumber,
  }
}

export function getIPWhiteListError(errMsg, ipErrorNotified) {
  if (window.location.href.includes('/login')) {
    return
  }
  if (errMsg && errMsg.startsWith(IP_ERROR_MSG) && !ipErrorNotified) {
    notifyError(IP_ERROR_MSG)
    moveToLoginPage(redirect, true)
    ipErrorNotified = true
    setTimeout(() => {
      ipErrorNotified = false
    }, 3000)
  }
}

export function usdCurrencyFormat(amount) {
  let formattedNumber = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)

  return formattedNumber
}

export function formatVehiclePrice(amount) {
  const num = parseInt(amount.replace(/[^\d.]/g, ''))

  const formatted = num
    .toLocaleString('en-US', { maximumFractionDigits: 0 })
    .replace('.', ' ')
    .replace(',', ' ')

  return `${formatted} $`
}

export const getStartDateForDashboard = (selectedRange, customRange) => {
  const DATE_FORMAT = 'MM/DD/YYYY'
  switch (selectedRange) {
    case 'ytd':
      return {
        start_date: utilDate.getStartOfDay(
          utilDate.getYesterdayByDayJSUTC(),
          DATE_FORMAT
        ),
        end_date: utilDate.getEndOfDay(
          utilDate.getYesterdayByDayJSUTC(),
          DATE_FORMAT
        ),
      }
    case 'today':
      return {
        start_date: utilDate.getStartOfDay(utilDate.getDateDaysJSUTC(), DATE_FORMAT),
        end_date: utilDate.getEndOfDay(utilDate.getDateDaysJSUTC(), DATE_FORMAT),
      }
    case 'mtd':
      return {
        start_date: utilDate.getDateDaysJSUTC(
          utilDate.getStartOfMonthDaysJ(),
          DATE_FORMAT
        ),
        end_date: utilDate.getDateDaysJSUTC(utilDate.getDateByDayJS(), DATE_FORMAT),
      }
    case 'last_month':
      return {
        start_date: utilDate.getDateDaysJSUTC(
          utilDate.getStartOfMonthDaysJ(utilDate.getLastMonthByDayJS()),
          DATE_FORMAT
        ),
        end_date: utilDate.getEndOfMonthDaysJ(
          utilDate.getStartOfMonthDaysJ(utilDate.getLastMonthByDayJS()),
          DATE_FORMAT
        ),
      }
    case 'custom':
      return {
        start_date: utilDate.getStartOfDay(
          utilDate.getDateDaysJSUTC(customRange ? customRange.start_date : ''),
          DATE_FORMAT
        ),
        end_date: utilDate.getEndOfDay(
          utilDate.getDateDaysJSUTC(customRange ? customRange.end_date : ''),
          DATE_FORMAT
        ),
      }
    default:
      return {
        start_date: '01/01/1974',
        end_date: utilDate.getDateDaysJSUTC(utilDate.getDateByDayJS(), DATE_FORMAT),
      }
  }
}

export function convertNumberTo5PointSystemValue(
  is5StarReviewEnabled = false,
  value = 1
) {
  if (is5StarReviewEnabled) {
    return Math.ceil((value || 1) / 2)
  }
  return value
}

export function replaceWords(str, replacements) {
  const pattern = /\[([^\]]+)]/gi

  return str.replace(pattern, (match, word) => {
    const replacement = replacements[word]
    return replacement ? replacement : match
  })
}

export function localizationText(text) {
  if (text === 'days') {
    return getText('WORD_DAYS').toLowerCase()
  } else if (text === 'hours') {
    return getText('WORD_HOURS').toLowerCase()
  } else if (text === 'minutes') {
    return getText('WORD_MINUTES').toLowerCase()
  }
  return text
}

export function convertDashboardStatisticDataMonth(obj, isResponsiveSize) {
  const SORTED_LIST = isResponsiveSize ? MONTH_SHORT_ORDER : MONTH_ORDER
  if (!Boolean(obj)) return []
  const result = Object.entries(obj)
    .map(([name, value]) => ({
      name: isResponsiveSize
        ? getText(capitalize(name.substring(0, 3)))
        : getText(capitalize(name)),
      nameEng: isResponsiveSize
        ? capitalize(name.substring(0, 3))
        : capitalize(name),
      value,
    }))
    .sort(
      (a, b) =>
        SORTED_LIST.indexOf(a.nameEng.toLowerCase()) -
        SORTED_LIST.indexOf(b.nameEng.toLowerCase())
    )
  return result
}

export function voiceAIStatus(item) {
  const statuses = {
    unresolved: { title: getText('WORD_UNRESOLVED'), color: 'var(--orange)' },
    appointment: { title: getText('WORD_APPOINTMENT_SET'), color: 'var(--green)' },
    transferred: { title: getText('WORD_CALL_TRANSFERRED'), color: 'var(--blue)' },
    callback: { title: getText('WORD_CALLBACK_REQUESTED'), color: 'var(--yellow)' },
    infoRequest: {
      title: getText('WORD_INFORMATION_REQUEST'),
      color: 'var(--mainColor)',
    },
  }

  if (!item.success) return statuses.unresolved
  if (item.isAppointment) return statuses.appointment
  if (item.isCallTransferred) return statuses.transferred
  if (item.isCallbackNeed) return statuses.callback
  return statuses.infoRequest
}
