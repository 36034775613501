import React, { forwardRef, useMemo } from 'react'
import { Table } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { getText } from '../../../lang'
import LoadingSpinner from '../../LoadingSpinner'
import MyDefaultEmpty from '../../MyDefaultEmpty/MyDefaultEmpty'
import './MyDefaultTableList.scss'

const MyDefaultTableList = forwardRef(
  (
    {
      loading,
      initialLoad,
      pageStart,
      onLoadMore,
      isReverse,
      data,
      totalDataCount,
      inifiniteScrollClass,
      getScrollParent,
      height,
      emptyTitle,
      emptyButtonTitle,
      onEmptyClick,
      columns,
      onChange,
      scroll,
      expandable,
      rowKey,
      key,
      title,
      rowSelection,
      className,
      summary,
    },
    ref
  ) => {
    const newData = useMemo(() => {
      const list = (data || [])?.map((item, index) => {
        item.key = index
        return item
      })
      return list
    }, [data])

    return (
      <InfiniteScroll
        ref={ref}
        initialLoad={initialLoad}
        pageStart={pageStart}
        loadMore={onLoadMore}
        isReverse={isReverse}
        hasMore={!loading && data?.length < totalDataCount}
        useWindow={false}
        threshold={150}
        className={`my_default_infinite_scroll ${inifiniteScrollClass || ''}`}
        getScrollParent={getScrollParent}
        height={height}
      >
        {Boolean(loading) && <LoadingSpinner />}
        {Boolean(data?.length && (!loading || pageStart > 0)) ? (
          <Table
            className={`my_default_table_list ${className || ''}`}
            key={key}
            sticky={{
              offsetHeader: -24,
            }}
            tableLayout={'auto'}
            rowKey={rowKey}
            columns={columns}
            loading={loading}
            dataSource={newData}
            onChange={onChange}
            rowSelection={rowSelection}
            scroll={
              scroll || {
                x: 600,
              }
            }
            expandable={expandable}
            title={title}
            summary={summary}
            locale={{
              triggerDesc: getText('TEXT_CLICK_SORT_DESCENDING'),
              triggerAsc: getText('TEXT_CLICK_SORT_ASCENDING'),
              cancelSort: getText('TEXT_CLICK_SORT_CANCEL'),
            }}
            pagination={false}
          />
        ) : (
          !Boolean(loading) && (
            <MyDefaultEmpty
              title={emptyTitle}
              buttonText={emptyButtonTitle}
              onClick={onEmptyClick}
            />
          )
        )}
      </InfiniteScroll>
    )
  }
)

export default MyDefaultTableList
