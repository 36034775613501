import React, { useMemo } from 'react'
import { Flex, Tag } from 'antd'
import { getText } from '../../../../../lang'
import { MOBILE } from '../../../../../devOptions'
import MatButton from '../../../../../components/MatButton'
import ConfirmPopup from '../../../../../components/ConfirmPopup'
import './CenterMessageSuperhumanSnooze.scss'

const CenterMessageSuperhumanSnooze = ({ screenSize, disabled, onSnooz }) => {
  const isMobileView = useMemo(() => {
    return screenSize === MOBILE
  }, [screenSize])

  return (
    <Flex
      justify={'space-between'}
      align='center'
      className='superhuman_snoozed_block_wrapper'
    >
      <Flex align={isMobileView ? 'flex-start' : 'center'} vertical={isMobileView}>
        <Tag style={isMobileView ? { marginBottom: 5 } : {}} className='title-tag'>
          {getText('AI_BOT_SUPERHUMAN')}
        </Tag>
        {getText('TEXT_TAKE_PART_CONVERSATION')}
      </Flex>
      <ConfirmPopup
        title={getText('MSG_SNOOZE_SUPERHUMAN')}
        placement={'topRight'}
        onCancel={() => {}}
        onConfirm={() => {
          onSnooz && onSnooz()
        }}
        okText={getText('WORD_YES')}
        cancelText={getText('WORD_NO')}
        trigger={
          <MatButton
            className='snooze-tag'
            disabled={disabled}
            buttonText={getText('TEXT_SNOOZE_FOR_HOURS').replace('{{hours}}', 12)}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        }
      />
    </Flex>
  )
}

export default CenterMessageSuperhumanSnooze
