import React, { memo } from 'react'
import { Card, Flex } from 'antd'
import { getText } from '../../../../../lang'
import Div from '../../../../../components/MatDiv/Div'
import SVGDashInfluencedSalescon from '../../../../../icons/SVG/SVGDashInfluencedSalescon'
import MatButton from '../../../../../components/MatButton'
import './DashboardInfluencedSales.scss'

const DashboardInfluencedSales = () => {
  return (
    <Card className='dashboard_block dashboard_influenced_sales'>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        <SVGDashInfluencedSalescon />
        <Div className='dashboard_block--header--right'>
          <span>{getText('WORD_INFLUENCED_SALES')}</span>
        </Div>
      </Flex>
      <p className='dashboard_influenced_sales--text'>
        {getText('TEXT_INFLUENCED_SALES')}
      </p>
      <MatButton buttonText={getText('WORD_REQUEST_REPORT')} />
    </Card>
  )
}

export default memo(DashboardInfluencedSales)
