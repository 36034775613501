import React from 'react'
import Icon from '@ant-design/icons'
import { getText } from '../../lang'
import Div from '../../components/Div/Div'

const multipleLineText = (
  title,
  x,
  y,
  maxWidth = 100,
  lineHeight = '18px',
  textAlign = 'center',
  fontSize = 14
) => {
  return (
    <foreignObject x={x} y={y} width='100' height='50'>
      <Div
        xmlns='http://www.w3.org/1999/xhtml'
        style={{
          maxWidth: maxWidth,
          lineHeight: lineHeight,
          textAlign: textAlign,
          fontSize: fontSize,
        }}
      >
        {title}
      </Div>
    </foreignObject>
  )
}

const SVGIcon = ({
  profiles_messaged,
  avg_per_user_count,
  engaged_clients,
  ai_bot_messages,
  ai_bot_percentage,
  appointment_conversion_count,
}) => {
  return (
    <svg
      width='311'
      height='701'
      viewBox='0 0 311 701'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M164.261 122L164.261 187.642L164.261 216.66L164.261 657'
        stroke='url(#paint0_linear_17857_113679)'
        strokeWidth='42'
      />
      <path
        opacity='0.2'
        d='M164.261 122L164.261 187.326L164.261 216.891L164.261 642.353'
        stroke='white'
        strokeWidth='1.5'
      />
      <rect
        x='85.416'
        width='158'
        height='158'
        rx='79'
        fill='#24AADF'
        fillOpacity='0.12'
      />
      <circle cx='164.416' cy='79' r='64.1875' fill='#24AADF' />
      <path
        d='M223.257 79C223.257 111.497 196.913 137.841 164.416 137.841C131.919 137.841 105.575 111.497 105.575 79C105.575 46.5031 131.919 20.1591 164.416 20.1591C196.913 20.1591 223.257 46.5031 223.257 79Z'
        stroke='#24AADF'
        strokeWidth='0.818182'
      />
      <path
        d='M153.583 65.0605H175.25'
        stroke='white'
        strokeWidth='1.22727'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M161.979 45.5607V65.0607H166.854V45.5607C166.854 44.369 166.366 43.394 164.904 43.394H163.929C162.466 43.394 161.979 44.369 161.979 45.5607Z'
        stroke='white'
        strokeWidth='1.22727'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M154.666 52.0607V65.0607H158.999V52.0607C158.999 50.869 158.566 49.894 157.266 49.894H156.399C155.099 49.894 154.666 50.869 154.666 52.0607Z'
        stroke='white'
        strokeWidth='1.22727'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.832 57.4772V65.0605H174.165V57.4772C174.165 56.2855 173.732 55.3105 172.432 55.3105H171.565C170.265 55.3105 169.832 56.2855 169.832 57.4772Z'
        stroke='white'
        strokeWidth='1.22727'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <text
        x='163.521'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={12}
        fontWeight={500}
        y='78.421'
        fill='white'
      >
        {getText('WORD_LEAD_VOLUME')}
      </text>
      <text
        x='163.521'
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={24}
        fontWeight={600}
        y='105.421'
        fill='white'
      >
        {profiles_messaged}
      </text>
      <text
        x='218'
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={16}
        fontWeight={500}
        y='657.421'
        fill='white'
      >
        {appointment_conversion_count}
      </text>
      <rect
        x='120.458'
        y='613'
        width='88'
        height='88'
        rx='44'
        fill='#F6BA3A'
        fillOpacity='0.12'
      />
      <circle cx='164.458' cy='657' r='35.75' fill='#E7AB2B' />
      <path
        d='M196.958 657C196.958 674.949 182.407 689.5 164.458 689.5C146.509 689.5 131.958 674.949 131.958 657C131.958 639.051 146.509 624.5 164.458 624.5C182.407 624.5 196.958 639.051 196.958 657Z'
        stroke='#FFD479'
      />
      <path
        d='M159.125 643.667V647.667'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.791 643.667V647.667'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M153.125 653.12H175.792'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M176.458 652.333V663.667C176.458 667.667 174.458 670.333 169.791 670.333H159.125C154.458 670.333 152.458 667.667 152.458 663.667V652.333C152.458 648.333 154.458 645.667 159.125 645.667H169.791C174.458 645.667 176.458 648.333 176.458 652.333Z'
        stroke='white'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.384 659.267H169.396'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.384 663.267H169.396'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.452 659.267H164.464'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.452 663.267H164.464'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M159.517 659.267H159.529'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M159.517 663.267H159.529'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {multipleLineText(getText('WORD_APPOINTMENTS_CONVERSION'), 123, 572, 80)}
      {multipleLineText(getText('WORD_REPLY_SUCCSESS_RATE'), 6, 488)}
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184 479H59L59 391H184L184 479Z'
        fill='#B4EAFF'
      />
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185.068 457H56.9443L56.9443 413H185.068L185.068 457Z'
        fill='#B4EAFF'
      />
      <rect
        x='121.553'
        y='391'
        width='88'
        height='88'
        rx='44'
        fill='#86FFDE'
        fillOpacity='0.12'
      />
      <circle cx='165.553' cy='435' r='35.75' fill='#19BE83' />
      <path
        d='M198.053 435C198.053 452.949 183.502 467.5 165.553 467.5C147.603 467.5 133.053 452.949 133.053 435C133.053 417.051 147.603 402.5 165.553 402.5C183.502 402.5 198.053 417.051 198.053 435Z'
        stroke='#2FE6B4'
      />
      <path
        d='M161.766 433.493C161.633 433.48 161.473 433.48 161.326 433.493C158.153 433.387 155.633 430.787 155.633 427.587C155.633 424.32 158.273 421.667 161.553 421.667C164.819 421.667 167.473 424.32 167.473 427.587C167.459 430.787 164.939 433.387 161.766 433.493Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M171.433 424.333C174.02 424.333 176.1 426.427 176.1 429C176.1 431.52 174.1 433.573 171.606 433.667C171.5 433.653 171.38 433.653 171.26 433.667'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M155.1 438.413C151.873 440.573 151.873 444.093 155.1 446.24C158.766 448.693 164.78 448.693 168.446 446.24C171.673 444.08 171.673 440.56 168.446 438.413C164.793 435.973 158.78 435.973 155.1 438.413Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M174.006 445.667C174.966 445.467 175.873 445.08 176.619 444.507C178.699 442.947 178.699 440.373 176.619 438.813C175.886 438.253 174.993 437.88 174.046 437.667'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='61.5205' cy='435' r='44' fill='#3B3F6A' />
      <circle cx='61.5205' cy='435' r='43.5' stroke='#5C97AD' strokeOpacity='0.3' />
      {multipleLineText(getText('WORD_ENGAGED_CLIENTS'), 123, 352, 80)}
      <path
        d='M71.7401 409.577C68.4303 408.206 64.883 407.5 61.3005 407.5C57.718 407.5 54.1707 408.206 50.8609 409.577C47.5512 410.948 44.5438 412.957 42.0106 415.49C39.4775 418.023 37.468 421.031 36.0971 424.34C34.7261 427.65 34.0205 431.198 34.0205 434.78C34.0205 438.362 34.7261 441.91 36.0971 445.22C37.468 448.529 39.4774 451.537 42.0106 454.07C44.5438 456.603 47.5511 458.612 50.8609 459.983C54.1707 461.354 57.718 462.06 61.3005 462.06C64.883 462.06 68.4303 461.354 71.7401 459.983C75.0499 458.612 78.0572 456.603 80.5904 454.07C83.1236 451.537 85.133 448.529 86.5039 445.22C87.8749 441.91 88.5805 438.362 88.5805 434.78C88.5805 431.198 87.8749 427.65 86.5039 424.34C85.133 421.031 83.1236 418.023 80.5904 415.49C78.0572 412.957 75.0499 410.948 71.7401 409.577Z'
        fill='#19BE83'
      />
      <path
        d='M61.3005 407.5C64.883 407.5 68.4303 408.206 71.7401 409.577C75.0499 410.948 78.0572 412.957 80.5904 415.49C83.1236 418.023 85.133 421.031 86.5039 424.34C87.8749 427.65 88.5805 431.198 88.5805 434.78C88.5805 438.362 87.8749 441.91 86.5039 445.22C85.133 448.529 83.1236 451.537 80.5904 454.07C78.0572 456.603 75.0499 458.612 71.7401 459.983C68.4303 461.354 64.883 462.06 61.3005 462.06C57.718 462.06 54.1707 461.354 50.8609 459.983C47.5511 458.612 44.5438 456.603 42.0106 454.07C39.4774 451.537 37.468 448.529 36.0971 445.22C34.7261 441.91 34.0205 438.362 34.0205 434.78C34.0205 431.198 34.7261 427.65 36.0971 424.34C37.468 421.031 39.4775 418.023 42.0106 415.49C44.5438 412.957 47.5512 410.948 50.8609 409.577C54.1707 408.206 57.718 407.5 61.3005 407.5Z'
        fill='#19BE83'
      />
      <text
        x='218'
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={16}
        fontWeight={500}
        y='435'
        fill='white'
      >
        {engaged_clients}
      </text>
      <text
        x={60.521}
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={16}
        fontWeight={500}
        y='436.421'
        fill='white'
      >
        {ai_bot_percentage}%
      </text>
      <path
        d='M61.5203 399.8C66.1428 399.8 70.7201 400.711 74.9908 402.479C79.2614 404.248 83.1419 406.841 86.4105 410.11C89.6791 413.379 92.2719 417.259 94.0409 421.53C95.8098 425.8 96.7203 430.378 96.7203 435C96.7203 439.623 95.8098 444.2 94.0409 448.471C92.2719 452.741 89.6791 456.622 86.4105 459.89C83.1418 463.159 79.2614 465.752 74.9908 467.521C70.7201 469.29 66.1428 470.2 61.5203 470.2C56.8978 470.2 52.3205 469.29 48.0498 467.521C43.7792 465.752 39.8988 463.159 36.6301 459.89C33.3615 456.622 30.7687 452.741 28.9997 448.47C27.2308 444.2 26.3203 439.623 26.3203 435C26.3203 430.378 27.2308 425.8 28.9998 421.53C30.7687 417.259 33.3615 413.378 36.6302 410.11C39.8988 406.841 43.7792 404.248 48.0499 402.479C52.3205 400.711 56.8978 399.8 61.5203 399.8L61.5203 399.8Z'
        stroke='#48547A'
        strokeWidth='10'
      />
      <defs>
        <linearGradient id='progressBarGradient' x1='1' y1='1' x2='0' y2='0'>
          <stop stopColor='#19BE83' />
          <stop offset='1' stopColor='#00E4C9' />
        </linearGradient>
      </defs>
      <circle
        stroke='url(#progressBarGradient)'
        fill='none'
        strokeWidth={8}
        strokeDasharray={'219.912'}
        strokeLinecap={'round'}
        strokeDashoffset={`calc(219.912 * (1 - (30 / 100)))`}
        cx='61.221'
        transform='rotate(-90 61.221 435.221)'
        cy='435.221'
        r='35'
      ></circle>
      <g filter='url(#filter0_b_17857_113679)'>
        <rect
          x='6.57031'
          y='531'
          width='109.507'
          height='62'
          rx='6'
          fill='url(#paint2_linear_17857_113679)'
          fillOpacity='0.4'
        />
        <rect
          x='7.07031'
          y='531.5'
          width='108.507'
          height='61'
          rx='5.5'
          stroke='#EAECF0'
          strokeOpacity='0.2'
        />
        {multipleLineText(
          getText('WORD_INDUSTRY_AVERAGE_ENGAGEMENT'),
          17,
          533,
          90,
          '16px',
          undefined,
          10
        )}
        <text
          x={60.521}
          dominantBaseline='middle'
          textAnchor='middle'
          fontSize={14}
          fontWeight={600}
          y='580.421'
          fill='white'
        >
          44%
        </text>
      </g>
      {multipleLineText(getText('TEXT_MESSAGES_SENT'), 123, 173, 80)}
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M184 301H59L59 213L184 213L184 301Z'
        fill='#B4EAFF'
      />
      <path
        opacity='0.12'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M185.068 279H56.9443L56.9443 235L185.068 235L185.068 279Z'
        fill='#B4EAFF'
      />
      <rect
        x='121.553'
        y='213'
        width='88'
        height='88'
        rx='44'
        fill='#B4EAFF'
        fillOpacity='0.12'
      />
      <circle cx='165.553' cy='257' r='35.75' fill='#24AADF' />
      <path
        d='M198.053 257C198.053 274.949 183.502 289.5 165.553 289.5C147.603 289.5 133.053 274.949 133.053 257C133.053 239.051 147.603 224.5 165.553 224.5C183.502 224.5 198.053 239.051 198.053 257Z'
        stroke='#36BAEE'
      />
      <path
        d='M159.419 249.427L170.739 245.653C175.819 243.96 178.579 246.733 176.899 251.813L173.126 263.133C170.592 270.747 166.432 270.747 163.899 263.133L162.779 259.773L159.419 258.653C151.806 256.12 151.806 251.973 159.419 249.427Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M163.033 259.2L167.807 254.413'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {multipleLineText(getText('TEXT_AVG_MESSAGE_SENT_PER_USER'), 11, 310)}
      <circle cx='63.7119' cy='257' r='44' fill='#3B3F6A' />
      <circle cx='63.7119' cy='257' r='43.5' stroke='#5C97AD' strokeOpacity='0.3' />
      <path
        d='M74.1522 231.797C70.8425 230.426 67.2951 229.72 63.7126 229.72C60.1302 229.72 56.5828 230.426 53.273 231.797C49.9633 233.167 46.9559 235.177 44.4228 237.71C41.8896 240.243 39.8801 243.251 38.5092 246.56C37.1382 249.87 36.4326 253.417 36.4326 257C36.4326 260.582 37.1382 264.13 38.5092 267.44C39.8801 270.749 41.8896 273.757 44.4227 276.29C46.9559 278.823 49.9632 280.832 53.273 282.203C56.5828 283.574 60.1301 284.28 63.7126 284.28C67.2951 284.28 70.8424 283.574 74.1522 282.203C77.462 280.832 80.4693 278.823 83.0025 276.29C85.5357 273.757 87.5451 270.749 88.916 267.44C90.287 264.13 90.9926 260.582 90.9926 257C90.9926 253.418 90.287 249.87 88.9161 246.56C87.5451 243.251 85.5357 240.243 83.0025 237.71C80.4693 235.177 77.462 233.167 74.1522 231.797Z'
        fill='#24AADF'
      />
      <path
        d='M63.7126 229.72C67.2951 229.72 70.8425 230.426 74.1522 231.797C77.462 233.167 80.4693 235.177 83.0025 237.71C85.5357 240.243 87.5451 243.251 88.9161 246.56C90.287 249.87 90.9926 253.418 90.9926 257C90.9926 260.582 90.287 264.13 88.916 267.44C87.5451 270.749 85.5357 273.757 83.0025 276.29C80.4693 278.823 77.462 280.832 74.1522 282.203C70.8424 283.574 67.2951 284.28 63.7126 284.28C60.1301 284.28 56.5828 283.574 53.273 282.203C49.9632 280.832 46.9559 278.823 44.4227 276.29C41.8896 273.757 39.8801 270.749 38.5092 267.44C37.1382 264.13 36.4326 260.582 36.4326 257C36.4326 253.417 37.1382 249.87 38.5092 246.56C39.8801 243.251 41.8896 240.243 44.4228 237.71C46.9559 235.177 49.9633 233.167 53.273 231.797C56.5828 230.426 60.1302 229.72 63.7126 229.72Z'
        fill='#24AADF'
      />
      <text
        x={63.521}
        dominantBaseline='middle'
        textAnchor='middle'
        fontSize={16}
        fontWeight={500}
        y='258.421'
        fill='white'
      >
        {avg_per_user_count}
      </text>
      <path
        d='M63.7117 221.8C68.3343 221.8 72.9115 222.711 77.1822 224.479C81.4528 226.248 85.3333 228.841 88.6019 232.11C91.8705 235.379 94.4633 239.259 96.2323 243.53C98.0012 247.8 98.9117 252.378 98.9117 257C98.9117 261.623 98.0012 266.2 96.2323 270.471C94.4633 274.741 91.8705 278.622 88.6019 281.89C85.3332 285.159 81.4528 287.752 77.1822 289.521C72.9115 291.29 68.3342 292.2 63.7117 292.2C59.0892 292.2 54.5119 291.29 50.2413 289.521C45.9706 287.752 42.0902 285.159 38.8215 281.89C35.5529 278.622 32.9601 274.741 31.1912 270.47C29.4222 266.2 28.5117 261.623 28.5117 257C28.5117 252.378 29.4222 247.8 31.1912 243.53C32.9601 239.259 35.553 235.378 38.8216 232.11C42.0902 228.841 45.9706 226.248 50.2413 224.479C54.5119 222.711 59.0892 221.8 63.7117 221.8L63.7117 221.8Z'
        stroke='#48547A'
        strokeWidth='10'
      />
      <text
        x={217.421}
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={16}
        fontWeight={500}
        y='229.421'
        fill='white'
      >
        {ai_bot_messages}
      </text>
      <text
        x={217.421}
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={14}
        fontWeight={500}
        y='249.821'
        fill='white'
      >
        ({getText('WORD_SMS_UC')}, {getText('WORD_EMAIL')},
      </text>
      <text
        x={217.421}
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={14}
        fontWeight={500}
        y='268.221'
        fill='white'
      >
        {getText('WORD_SOCIAL_FB')},
      </text>
      <text
        x={217.421}
        dominantBaseline='middle'
        textAnchor='start'
        fontSize={14}
        fontWeight={500}
        y='286.421'
        fill='white'
      >
        {getText('WORD_SOCIAL_INSTAGRAM')})
      </text>
      <defs>
        <filter
          id='filter0_b_17857_113679'
          x='-33.4297'
          y='491'
          width='189.507'
          height='142'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='20' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_17857_113679'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_17857_113679'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_17857_113679'
          x1='163.521'
          y1='122'
          x2='163.521'
          y2='640.378'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5B66EA' />
          <stop offset='0.341725' stopColor='#16B9DC' />
          <stop offset='0.675' stopColor='#19BE83' />
          <stop offset='0.985' stopColor='#FFA51F' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_17857_113679'
          x1='61.5197'
          y1='399.8'
          x2='90.1197'
          y2='458.32'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#19BE83' />
          <stop offset='1' stopColor='#00E4C9' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_17857_113679'
          x1='110.793'
          y1='533.287'
          x2='8.25737'
          y2='552.653'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#443F91' />
          <stop offset='1' stopColor='#3E3DAE' />
        </linearGradient>
      </defs>
    </svg>
  )
}

const SVGDashSequenceMobileBarIcon = (props) => {
  return (
    <Icon
      component={() => {
        return <SVGIcon {...props} />
      }}
      {...props}
    />
  )
}

export default SVGDashSequenceMobileBarIcon
