import React from 'react'
import { Tooltip } from 'antd'
import Div from '../../../../components/Div/Div'

const DashboardInfoTooltip = ({ title, color }) => {
  return (
    <Tooltip title={title} trigger={['click', 'hover']}>
      <Div className={`dashboard_block--tooltip ${color || ''}`} />
      <Div className='dashboard_block--tooltip-block' />
    </Tooltip>
  )
}

export default DashboardInfoTooltip
