const utilNumber = {
  getNumberForAbove99: (number) => {
    if (!number || !Number.isInteger(number)) {
      return 0
    }
    return number > 99 ? '99+' : number
  },
  numberFractions: (number) => {
    if (!number || !Number.isInteger(number)) {
      return 0
    }
    return number
      .toLocaleString('en-US', { maximumFractionDigits: 0 })
      .replace(',', ' ')
  },
  formatNumberToK: (number) => {
    if (!number || !Number.isInteger(number)) {
      return 0
    }

    if (number >= 1_000_000)
      return (number / 1_000_000).toFixed(number % 1_000_000 === 0 ? 0 : 1) + 'kk'
    if (number >= 1000)
      return (number / 1000).toFixed(number % 1000 === 0 ? 0 : 1) + 'k'

    return number.toString()
  },
  formatSecondsToHms: (d) => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)

    const hDisplay = h > 0 ? h + 'h ' : ''
    const mDisplay = m > 0 ? m + 'm' : ''
    const sDisplay = s > 0 && m === 0 ? m + 1 + 'm' : 0
    const lastResult = sDisplay || mDisplay
    return d === 0 ? 0 : hDisplay + lastResult
  },
  formatMinutesToHours: (d) => {
    d = Number(d)
    const h = Math.floor(d / 60)
    const m = d % 60

    const hDisplay = h > 0 ? h + 'h ' : ''
    const mDisplay = m > 0 ? m + 'm' : ''

    return d === 0 ? 0 : hDisplay + mDisplay
  },
  getNumberWithDollars: (number) => {
    if (!number || !Number.isInteger(number)) {
      return `$0`
    }
    return `$${number.toLocaleString('en-US', { maximumFractionDigits: 0 })}`
  },
}

export default utilNumber
