import React, { memo } from 'react'
import { Card, Flex, Typography } from 'antd'
import utilNumber from '../../../../utils/utilNumber'
import Div from '../../../../components/MatDiv/Div'
import DashboardInfoTooltip from '../DashboardInfoTooltip/DashboardInfoTooltip'

const DashboardInfoBar = ({
  number,
  icon,
  title,
  color,
  customNumber,
  tooltipTitle,
}) => {
  return (
    <Card className='dashboard_block dashboard_unique_leads'>
      <Div className={`dashboard_block--blurred-part ${color}`}></Div>
      <Flex gap={8} align={'center'} className='dashboard_block--header'>
        {icon}
        <Div className='dashboard_block--header--right'>
          <span>
            {title}
            {tooltipTitle && <DashboardInfoTooltip title={tooltipTitle} />}
          </span>
        </Div>
      </Flex>
      <Flex
        align='center'
        style={{ marginLeft: 0 }}
        className={'dashboard_sent_statistics--information'}
      >
        <Typography level={1}>
          {customNumber || utilNumber.numberFractions(number)}
        </Typography>
      </Flex>
    </Card>
  )
}

export default memo(DashboardInfoBar)
