import React, { forwardRef } from 'react'

const Div = forwardRef(({ children, style, className }, ref) => {
  return (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  )
})

export default Div
