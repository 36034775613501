import React, { memo, useState } from 'react'
import { Tooltip } from 'antd'
import meetActions from '../../../../../store/modules/meetActions'
import SVGVideo from '../../../../../icons/SVG/SVGVideo'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import { msgTooltipText } from '../../../utils/messageUtils'

const MessageVideoCallButton = ({
  conversation,
  refreshConversation,
  disabled,
  isAwaitingConsent,
  mobileDeskSize,
  onCloseMobileDesk,
}) => {
  const [saving, setSaving] = useState(false)

  const handleSendCallRequest = async () => {
    let obj = {}
    if (conversation.kind === 'messenger') {
      obj.messengerId = conversation.receiver.messengerId
    } else {
      obj.phone = conversation.receiver.phone.replace('+', '')
    }
    setSaving(true)
    let result = await meetActions.createRoom(obj, conversation)
    onCloseMobileDesk && onCloseMobileDesk()
    setSaving(false)
    if (result.success) {
      refreshConversation(result.data.conversation)
    }
  }

  return conversation.kind !== 'messenger' &&
    conversation.receiver_phone &&
    !isAwaitingConsent ? (
    <MatButton
      tooltip={!mobileDeskSize}
      tooltipTitle={getText('WORD_VIDEO_CALL')}
      dataIndex={'video_call'}
      className='icon-size only-stroke footerAction'
      onClick={handleSendCallRequest}
      disabled={disabled}
      loading={saving}
      icon={<SVGVideo />}
      buttonText={mobileDeskSize ? getText('WORD_VIDEO_CALL') : ''}
    />
  ) : (
    <Tooltip
      title={
        msgTooltipText(conversation, isAwaitingConsent) || getText('WORD_VIDEO_CALL')
      }
    >
      <div
        dataIndex={'video_call'}
        className={'mat-btn icon-size only-stroke footerAction'}
        disabled={true}
      >
        <span className='ant-btn-icon'>
          <SVGVideo />
        </span>
        {mobileDeskSize ? (
          <span>
            {msgTooltipText(conversation, isAwaitingConsent) ||
              getText('WORD_VIDEO_CALL')}
          </span>
        ) : (
          ''
        )}
      </div>
    </Tooltip>
  )
}

export default memo(MessageVideoCallButton)
