import React from 'react'
import { Flex } from 'antd'
import './DashboardRow.scss'

const DashboardRow = ({ leftSide, rightSide }) => {
  return (
    <Flex align='center' justify='space-between' className='dashboard_row'>
      <span>{leftSide}</span>
      <span>{rightSide}</span>
    </Flex>
  )
}

export default DashboardRow
