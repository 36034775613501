import React from 'react'
import { Table } from 'antd'
import './MatTable.scss'
import { getText } from '../../lang'

const MatTable = ({
  columns,
  dataSource,
  onChange,
  position,
  scroll,
  defaultPageSize,
  paginationCurrentPage,
  paginationTotalCount,
  onPaginationChange,
  expandable,
  rowKey,
  key,
  loading,
  withoutPagination,
  title,
  rowSelection,
  className,
  summary,
  sticky,
  onRow,
  tableLayout,
}) => {
  return (
    <Table
      className={`mat-table-wrapper ${className || ''}`}
      key={key}
      sticky={sticky}
      rowKey={rowKey}
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      onChange={onChange}
      rowSelection={rowSelection}
      scroll={scroll}
      tableLayout={tableLayout}
      expandable={expandable}
      title={title}
      summary={summary}
      onRow={onRow}
      locale={{
        triggerDesc: getText('TEXT_CLICK_SORT_DESCENDING'),
        triggerAsc: getText('TEXT_CLICK_SORT_ASCENDING'),
        cancelSort: getText('TEXT_CLICK_SORT_CANCEL'),
      }}
      pagination={
        withoutPagination
          ? false
          : {
              position: position,
              defaultPageSize: defaultPageSize,
              current: paginationCurrentPage + 1,
              total: paginationTotalCount,
              onChange: (e) => {
                onPaginationChange(e - 1)
              },
              responsive: 'responsive',
              showLessItems: true,
              showQuickJumper: false,
              showSizeChanger: false,
              showTitle: false,
              showTotal: false,
            }
      }
    />
  )
}

export default MatTable
