import React from 'react'
import { Flex } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import Div from '../../../../components/MatDiv/Div'
import DashboardRow from '../../DashboardComponents/DashboardRow/DashboardRow'
import { getText } from '../../../../lang'
import DashboardTags from '../../DashboardComponents/DashboardTags/DashboardTags'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import ProfileLetters from '../../../../components/ProfileLetters'
import SVGDrawerMessages from '../../../../icons/SVG/SVGDrawerMessages'
import { getLongName, MainColor, voiceAIStatus } from '../../../../utils'
import SVGAiBotSmsIcon from '../../../../icons/SVG/SVGAiBotSmsIcon'
import SVGCallsIcon from '../../../../icons/SVG/SVGCallsIcon'
import SVGVehicleInterestIcon from '../../../../icons/SVG/SVGVehicleInterestIcon'
import SVGLanguage from '../../../../icons/SVG/SVGLanguage'
import utilNumber from '../../../../utils/utilNumber'
import utilDate from '../../../../utils/utilsDate'
import { notifySuccess } from '../../../../utils/Notify'
import InputTextarea from '../../../../components/InputTextarea'
import SVGCopyIcon from '../../../../icons/SVG/SVGCopyIcon'
import VoiceAIAudio from './VoiceAIAudio'
import './VoiceAIList.scss'

const VoiceAiListItemModal = ({ item }) => {
  const status = voiceAIStatus(item)
  return (
    <Flex className='voice_ai_list_item'>
      <Div className='voice_ai_list_item--left'>
        <DashboardRow
          leftSide={getText('WORD_STATUS')}
          rightSide={<DashboardTags text={status?.title} color={status?.color} />}
        />
        <DashboardRow
          leftSide={getText('WORD_CALLBACKS_SCHEDULED')}
          rightSide={getText(item?.isCallbackNeed ? 'WORD_YES' : 'WORD_NO')}
        />
        <MatBlockWithLabel black label={getText('WORD_CALLER_INFO')}>
          <Flex align='center'>
            <ProfileLetters
              className='medium'
              name={item?.nameSpecifiedByCustomer || ''}
              withoutIcon
            />
            <Div className='voice_ai_list_item--left-caller-info'>
              <Flex align='center' gap={8}>
                <span>{item?.nameSpecifiedByCustomer || ''}</span>
                <SVGDrawerMessages color={MainColor} width={20} height={20} />
              </Flex>
              <p>{item?.customer?.phone || item?.callFromPhone}</p>
            </Div>
          </Flex>
        </MatBlockWithLabel>
        <MatBlockWithLabel
          black
          label={getText('WORD_CONSENT')}
          className='voice_ai_list_item--left-consent-info'
        >
          <DashboardRow
            leftSide={
              <Flex gap={8} align='center'>
                <SVGAiBotSmsIcon width={16} height={16} /> {getText('WORD_SMS_UC')}
              </Flex>
            }
            rightSide={<DashboardTags text={getText('WORD_APPROVED')} />}
          />
          <DashboardRow
            leftSide={
              <Flex gap={8} align='center'>
                <SVGCallsIcon /> {getText('WORD_CALL')}
              </Flex>
            }
            rightSide={<DashboardTags text={getText('WORD_APPROVED')} />}
          />
        </MatBlockWithLabel>
        <MatBlockWithLabel black className='voice_ai_list_item--left-vehicle-info'>
          <SVGVehicleInterestIcon backgroundcolor={'rgba(120, 84, 246, 0.20)'} />
          <Div className='voice_ai_list_item--left-vehicle-info-inner'>
            <p>{getText('WORD_VEHICLE_OF_INTEREST')}</p>
            <p>{`${item?.vehice?.year || ''} ${item?.vehicle?.make || ''} ${item?.vehicle?.model || ''}`}</p>
            <p>{item?.vehice?.vin || ''}</p>
          </Div>
        </MatBlockWithLabel>
        <MatBlockWithLabel black className='voice_ai_list_item--left-consent-info'>
          <DashboardRow
            leftSide={
              <Flex gap={8} align='center'>
                <SVGLanguage backgroundcolor={'rgba(234, 153, 87, 0.2)'} />{' '}
                {getText('WORD_LANGUAGE')}
              </Flex>
            }
            rightSide={
              <DashboardTags
                style={{
                  color: 'var(--green)',
                  border: '1px solid var(--borderLineColor)',
                }}
                text={getLongName(item?.language || 'en')}
                color={'transparent'}
              />
            }
          />
        </MatBlockWithLabel>
      </Div>
      <Div className='voice_ai_list_item--right'>
        {Boolean(item?.recording?.playback_id) && (
          <VoiceAIAudio idPlayBack={item?.recording?.playback_id} />
        )}
        <Div className={'voice_ai_list_item--right-list-info'}>
          <DashboardRow
            leftSide={getText('WORD_DATE')}
            rightSide={utilDate.getLocalizedFullDate(item?.createdAt, 'MMM D, YYYY')}
          />
          <DashboardRow
            leftSide={getText('WORD_DURATION')}
            rightSide={
              item?.durationSeconds
                ? `${Math.floor(item?.durationSeconds / 60)}:${String(Math.floor(item?.durationSeconds % 60)).padStart(2, '0')} min`
                : 'N/A'
            }
          />
          <DashboardRow
            leftSide={getText('WORD_ESTIMATED_VALUE')}
            rightSide={utilNumber.getNumberWithDollars(item?.value || 0)}
          />
          <DashboardRow
            leftSide={getText('WORD_APPOINTMENT_TEXT')}
            rightSide={utilDate.getLocalizedFullDate(
              item?.appointmentDate,
              'MMM D, YYYY, hh:mm A'
            )}
          />
          <DashboardRow
            leftSide={getText('WORD_TYPE')}
            rightSide={item?.reason || 'N/A'}
          />
          <DashboardRow
            leftSide={getText('TEXT_OF_TIMES_CALLED')}
            rightSide={item?.numberOfTimesCalled || 0}
          />
        </Div>
        <MatBlockWithLabel
          black
          label={getText('WORD_SUMMARY')}
          className={'voice_ai_list_item--right-copy-part'}
        >
          <InputTextarea autoSize={false} value={item?.summary || ''} disabled />
          <CopyToClipboard
            text={item?.summary || ''}
            onCopy={() => notifySuccess(getText('WORD_COPIED'))}
          >
            <SVGCopyIcon className={'copy_icon'} />
          </CopyToClipboard>
        </MatBlockWithLabel>
      </Div>
    </Flex>
  )
}

export default VoiceAiListItemModal
