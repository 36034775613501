import React from 'react'
import Div from '../Div/Div'
import './MyDefaultTagBlock.scss'

const MyDefaultTagBlock = ({
  backgroundColor = 'var(--mainColorLighter)',
  title,
  titleColor = 'var(--mainColor)',
  disableMargin,
  border,
  height,
  onClick,
  fontSize,
}) => {
  return (
    <Div
      className={`my_default_tag_block ${disableMargin ? 'disableMargin' : ''}`}
      style={{
        backgroundColor: backgroundColor,
        color: titleColor,
        border: border,
        height: height,
        fontSize: fontSize,
      }}
      onClick={onClick}
    >
      {title}
    </Div>
  )
}

export default MyDefaultTagBlock
