import React, { memo, useEffect, useRef, useState } from 'react'
import { Tabs } from 'antd'
import { getText } from '../../lang'
import Div from '../../components/MatDiv/Div'
import useWindowSize from '../../utils/useWindowSize'
import { ALL_TIME, ORG } from '../../devOptions'
import DashboardMainFilters from './DashboardFilters/DashboardHeaderFilters'
import Video from './Video/Video'
import OverviewAndSuperhuman from './OverviewAndSuperhuman/OverviewAndSuperhuman'
import DashboardUsers from './Users/DashboardUsers'
import VoiceAI from './VoiceAI/VoiceAI'
import './Dashboard.scss'

export const DashboardContext = React.createContext({})

const Dashboard = ({ setPageTitle }) => {
  const dashboardUserPageRef = useRef(null)
  const paginationChangeRef = useRef(null)

  const { isMobile } = useWindowSize()

  const [selectedDataType, setSelectedDataType] = useState(ORG)
  const [selectedRange, setSelectedRange] = useState(ALL_TIME)
  const [selectCustomRange, setSelectCustomRange] = useState('')

  useEffect(() => {
    setPageTitle(getText('TITLE_DASHBOARD'))
  }, [])

  const tabItems = [
    {
      key: getText('WORD_OVERVIEW_SUPERHUMAN'),
      label: getText('WORD_OVERVIEW_SUPERHUMAN'),
      children: <OverviewAndSuperhuman />,
    },
    // {
    //   key: getText('WORD_REPUTATION_PULSE'),
    //   label: getText('WORD_REPUTATION_PULSE'),
    //   children: getText('WORD_REPUTATION_PULSE'),
    // },
    {
      key: getText('WORD_VIDEO'),
      label: getText('WORD_VIDEO'),
      children: <Video />,
    },
    // {
    //   key: getText('WORD_CAMPAIGNS'),
    //   label: getText('WORD_CAMPAIGNS'),
    //   children: getText('WORD_CAMPAIGNS'),
    // },
    // {
    //   key: getText('WORD_MARKETING'),
    //   label: getText('WORD_MARKETING'),
    //   children: getText('WORD_MARKETING'),
    // },
    {
      key: getText('WORD_USERS'),
      label: getText('WORD_USERS'),
      children: <DashboardUsers ref={paginationChangeRef} />,
    },
    {
      key: getText('WORD_VOICE_AI'),
      label: getText('WORD_VOICE_AI'),
      children: <VoiceAI />,
    },
  ]

  return (
    <DashboardContext.Provider
      value={{
        selectedRange,
        setSelectCustomRange,
        selectCustomRange,
        dashboardUserPageRef,
        setSelectedRange,
        setSelectedDataType,
        selectedDataType,
        paginationChangeRef,
      }}
    >
      <Div ref={dashboardUserPageRef} className={'dashboard_page_main_page'}>
        {isMobile && <DashboardMainFilters />}
        <Tabs
          defaultActiveKey={getText('WORD_OVERVIEW_SUPERHUMAN')}
          className='dashboard_page_main_page--tabs'
          tabBarExtraContent={{
            right: isMobile ? null : <DashboardMainFilters />,
          }}
          items={tabItems}
        />
      </Div>
    </DashboardContext.Provider>
  )
}

export default memo(Dashboard)
